import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Button, Col, Input, Label, Modal, Navbar, Row } from "reactstrap";
import { Select } from "../../../components/Inputs/Input";
import * as actions from "../../../store/modules/conferenciaCp/actions";
import * as controller from "../../../controllers/conferenciaCpController";

const ModalEdicao = ({ isOpen, toggle, id, pagination }) => {
  const { optionsSelect, item } = useSelector((state) => state.conferenciaCp);

  const onChange = useCallback((value, field) => {
    actions.setItemField(value, field);
  }, []);

  const onSave = useCallback(async () => {
    const success = await controller.alteraSistemaContaPagar(id, item.sistemaId);
    if (success) {
      await controller.getContasPagarAgrupada(
        pagination.search,
        pagination.pageSize,
        pagination.selectedPage
      );
      toggle();
    }
  }, [id, item.sistemaId, toggle, pagination]);

  const onCancel = useCallback(() => {
    actions.cleanItem();
    toggle();
  }, [toggle]);

  return (
    <Modal
      className="text-center"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={true}
      size="lg"
    >
      <Navbar
        expand="md"
        className="w-100 mb-2"
        style={{
          height: "40px",
          background: "var(--color-gradiente-verde)",
        }}
      >
        <h5 className="mx-auto mt-2 text-white">Detalhamento Conferência</h5>
      </Navbar>
      <div className="container-fluid my-3">
        <Row>
          <Col md={4}>
            <Label>Tipo Pagamento</Label>
            <Select
              first="Selecione"
              value={{ value: item.tipoRegraId }}
              select={{ value: "id", label: "descricao" }}
              options={optionsSelect.tiposPagamento}
              onChange={(e) => {
                onChange(e.value, "tipoRegraId");
              }}
              isSearchable={true}
              autoComplete="off"
              disabled
            />
          </Col>
          <Col md={4}>
            <Label>Sistema</Label>
            <Select
              first="Selecione"
              value={{ value: item.sistemaId }}
              select={{ value: "id", label: "nome" }}
              options={optionsSelect.sistemas}
              onChange={(e) => {
                onChange(e.value, "sistemaId");
              }}
              isSearchable={true}
              autoComplete="off"
            />
          </Col>
          <Col md={4}>
            <Label>Total Pago</Label>
            <Input
              type="text"
              value={item.valor}
              onChange={(e) => onChange(e.target.value, "valor")}
              disabled
            />
          </Col>
        </Row>
        <Row className="justify-content-center mt-5">
          <Col md={2}>
            <Button
              style={{
                width: "100%",
                height: "38px",
                backgroundColor: "var(--color-laranja)",
                borderColor: "var(--color-laranja)",
              }}
              onClick={() => {
                onCancel();
              }}
            >
              Cancelar
            </Button>
          </Col>
          <Col md={2}>
            <Button
              className="purpleButton"
              style={{
                width: "100%",
                height: "38px",
              }}
              onClick={() => {
                onSave();
              }}
            >
              Salvar
            </Button>
          </Col>
        </Row>
      </div>
    </Modal>
  );
};

export default ModalEdicao;
