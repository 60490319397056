import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardImg,
  Col,
  Input,
  Label,
  Row,
  FormFeedback,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import logo from "../../assets/images/logo.svg";
import version from "../../../package.json";
import * as esqueceuSenhaController from "../../controllers/esqueceuSenhaController";
import * as esqueceuSenhaActions from "../../store/modules/esqueceuSenha/actions";
import { useSelector } from "react-redux";
import { isAuthenticated } from "../../util/auth";
import { Link, Redirect, useParams } from "react-router-dom";
import history from "../../util/history";
import TopLoading from "../../components/Loading/TopLoading";
import "./esqueceuSenha.css";

const EsqueceuSenha = () => {
  const { item, invalid, loadingEsqueceuSenha } = useSelector(
    (state) => state.esqueceuSenha
  );
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [passwordValidations, setPasswordValidations] = useState({
    length: false,
    number: false,
    upper: false,
    lower: false,
    special: false,
  });

  const validatePassword = (password) => {
    const validations = {
      length: password.length >= 10,
      number: /\d/.test(password),
      upper: /[A-Z]/.test(password),
      lower: /[a-z]/.test(password),
      special: /[!@#$%^&*(),.?":{}|<>]/.test(password),
    };
    setPasswordValidations(validations);
    return Object.values(validations).every(Boolean);
  };

  const onChangePassword = (e) => {
    const value = e.target.value;
    setPassword(value);
    validatePassword(value);
  };

  const onChangeConfirmPassword = (e) => {
    setConfirmPassword(e.target.value);
  };

  let { guid } = useParams();

  useEffect(() => {
    esqueceuSenhaActions.cleanItem();
  }, []);

  useEffect(() => {
    async function validador() {
      (await esqueceuSenhaController.validaGuid(guid)) &&
        history.push("/login");
    }
    validador();
  }, [guid]);

  const handleSubmit = async () => {
    if (password !== confirmPassword) {
      esqueceuSenhaActions.setInvalid(true, "confirmaSenha");
      return;
    }

    if (!validatePassword(password)) {
      esqueceuSenhaActions.setInvalid(true, "novaSenha");
      return;
    }

    try {
      await esqueceuSenhaController.recuperarSenha(
        { novaSenha: password, confirmaSenha: confirmPassword },
        guid
      );
      history.push("/login");
    } catch (error) {
      console.error("Failed to recover password:", error);
    }
  };

  return !isAuthenticated() ? (
    <div className="container-full background-login">
      {loadingEsqueceuSenha && <TopLoading />}
      <Col>
        <Card
          style={{ width: "400px" }}
          className="card-lock align-center ml-auto mr-auto"
        >
          <CardBody>
            <Col >
              <Col
                className="text-center"
                style={{ margin: " 1rem auto 2rem auto", width: "250px" }}
              >
                <CardImg top src={logo} style={{ width: "40%" }} />
              </Col>
              <Row>
                <Label>Nova senha</Label>
                <InputGroup className="mb-3">
                  <Input
                    type={showPassword ? "text" : "password"}
                    value={password}
                    onChange={onChangePassword}
                    invalid={invalid.novaSenha}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback invalid={invalid.novaSenha}>
                    A senha deve atender aos critérios de complexidade.
                  </FormFeedback>
                </InputGroup>
              </Row>
              <Row className="mt-2 mb-2">
                <ul className="password-criteria">
                  <li className={passwordValidations.length ? "valid" : ""}>
                    Ter no mínimo 10 caracteres
                  </li>
                  <li className={passwordValidations.number ? "valid" : ""}>
                    Ter no mínimo 1 número
                  </li>
                  <li className={passwordValidations.upper ? "valid" : ""}>
                    Ter no mínimo 1 letra maiúscula
                  </li>
                  <li className={passwordValidations.lower ? "valid" : ""}>
                    Ter no mínimo 1 letra minúscula
                  </li>
                  <li className={passwordValidations.special ? "valid" : ""}>
                    Ter no mínimo 1 caractere especial
                  </li>
                </ul>
              </Row>
              <Row>
                <Label>Confirmar a nova senha</Label>
                <InputGroup>
                  <Input
                    type={showConfirmPassword ? "text" : "password"}
                    value={confirmPassword}
                    onChange={onChangeConfirmPassword}
                    invalid={invalid.confirmaSenha}
                  />
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      onClick={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    >
                      {showConfirmPassword ? <FaEyeSlash /> : <FaEye />}
                    </InputGroupText>
                  </InputGroupAddon>
                  <FormFeedback invalid={invalid.confirmaSenha}>
                    As senhas não coincidem.
                  </FormFeedback>
                </InputGroup>
              </Row>
              <Row className="mt-4">
                <Col className="text-center">
                  <Button className="login-button mb-2" onClick={handleSubmit}>
                    Enviar
                  </Button>
                  <Button className="login-button mt-2" tag={Link} to="/login">
                    Voltar
                  </Button>
                </Col>
              </Row>
            </Col>
          </CardBody>
        </Card>
      </Col>
      <p
        style={{
          color: "white",
          display: "flex",
          marginTop: "auto",
          marginRight: "10px",
        }}
      >{`v${version.version}`}</p>
    </div>
  ) : (
    <Redirect to="/dashboard" />
  );
};

export default EsqueceuSenha;
