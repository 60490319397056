const STORE_ID = "CONFERENCIA_CP";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    CLEAN_SEARCH: 'CLEAN_SEARCH',
    SET_OPTIONS_SELECT: 'SET_OPTIONS_SELECT',
    SET_SEARCH: 'SET_SEARCH',
    SET_CONTAS_PAGAR_AGRUPADO: 'SET_CONTAS_PAGAR_AGRUPADO',
    SET_CONTA_PAGAR_AGRUPADO_ITENS: 'SET_CONTA_PAGAR_AGRUPADO_ITENS',
    SET_PAGINATION: 'SET_PAGINATION',
    SET_ITEM: 'SET_ITEM',
    CLEAN_ITEM: 'CLEAN_ITEM',
    SET_ITEM_FIELD: 'SET_ITEM_FIELD',
    SET_DOWNLOAD: 'SET_DOWNLOAD',
}

export { STORE_ID, TYPES }