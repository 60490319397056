const STORE_ID = "ACEITE_CP";

const TYPES = {
    SET_PERMITIONS: 'SET_PERMITIONS',
    SEARCH: "SEARCH",
    ITEMS: "ITEMS",
    ITEMS_TOTAL: "ITEMS_TOTAL",
    CLEAN_REGISTER: "CLEAN_REGISTER",
    SET_ACTIVE_TAB: "SET_ACTIVE_TAB",
    SET_SEE: "SET_SEE",
    SET_EMPRESAS_FUNCIONARIO: "SET_EMPRESAS_FUNCIONARIO",
    SET_CAMPANHA_TIPO: "SET_CAMPANHA_TIPO",
    SET_STATUS_INTEGRACAO: "SET_STATUS_INTEGRACAO",
    SET_ITEM: 'SET_ITEM',
    ITEM: 'ITEM',
    SET_STATUS: 'SET_STATUS',
    STATUS_FAVORECIDO: 'STATUS_FAVORECIDO',
    HIERARQUIA_ID: 'HIERARQUIA_ID',
    SET_SISTEMAS: 'SET_SISTEMAS',
    GET_ALL_ITENS: 'GET_ALL_ITENS',
    GET_ALL_ITENS_CADASTRO: 'GET_ALL_ITENS_CADASTRO',
    SET_CANAIS: 'SET_CANAIS',
    SET_CAMPANHA: 'SET_CAMPANHA',
    SET_HIERARQUIAS: 'SET_HIERARQUIAS',
    SET_GRUPOS: 'SET_GRUPOS',
    SET_TIPOS_PARCERIA: 'SET_TIPOS_PARCERIA',
    SET_TIPOS_COMISSAO: 'SET_TIPOS_COMISSAO',
    SET_CONSULTORES: 'SET_CONSULTORES',
    SET_MODAL_CONSULTORES: 'SET_MODAL_CONSULTORES',
    SET_GET_ALL_CONSULTORES: 'SET_GET_ALL_CONSULTORES',
    SET_CENTROS_CUSTO: 'SET_CENTROS_CUSTO',
    CLEAN_SEARCH: "CLEAN_SEARCH",
    ENABLE_CHECKBOXES: "ENABLE_CHECKBOXES",
    CLEAN_MODAL_EDICAO: "CLEAN_MODAL_EDICAO",
    SET_MODAL_EDICAO: "SET_MODAL_EDICAO",
    SET_MODAL_EDICAO_FIELD: "SET_MODAL_EDICAO_FIELD",
    SET_SELECTED_ITEMS: "SET_SELECTED_ITEMS",
    
}

export { STORE_ID, TYPES }