import { setPermitions as personas } from "../store/modules/personas/personas_store/actions";
import { setPermitions as layoutParametrizacao } from "../store/modules/layoutParametrizacao/actions";
import { setPermitions as campanhaPremiacao } from "../store/modules/campanhaPremiacao/campanha/actions";
import { setPermitions as vinculoCentroDeCusto } from "../store/modules/vinculoCentroDeCusto/actions";
import { setPermitions as centroDeCusto } from "../store/modules/centroCusto/actions";
import { setPermitions as comissao } from "../store/modules/comissao/actions";
import { setPermitions as contasAPagar } from "../store/modules/contasAPagar/actions";
import { setPermitions as contasReceber } from "../store/modules/contasReceber/actions";
import { setPermitions as convenioInstituicao } from "../store/modules/convenioInstituicao/actions";
import { setPermitions as conveniosRibercred } from "../store/modules/conveniosRibercred/actions";
import { setPermitions as divisaoComissao } from "../store/modules/divisaoComissao/actions";
import { setPermitions as formalizacaoLoja } from "../store/modules/formalizacaoLoja/actions";
import { setPermitions as formalizacaoMatriz } from "../store/modules/formalizacaoMatriz/actions";
import { setPermitions as grupos } from "../store/modules/grupos/actions";
import { setPermitions as hierarquia } from "../store/modules/hierarquia/actions";
import { setPermitions as inconsistenciaComissao } from "../store/modules/inconsistenciaComissao/actions";
import { setPermitions as inconsistenciaProducao } from "../store/modules/inconsistenciaProducao/actions";
import { setPermitions as inconsistenciaComissaoRecebida } from "../store/modules/inconsistenciaComissaoRecebida/actions";
import { setPermitions as inconsistenciaProposta } from "../store/modules/inconsistenciaProposta/actions";
import { setPermitions as inconsistenciaFormalizacao } from "../store/modules/inconsistenciaFormalizacao/actions";
import { setPermitions as inconsistencias } from "../store/modules/inconsistencias/actions";
import { setPermitions as operacaoInstituicao } from "../store/modules/operacaoInstituicao/actions";
import { setPermitions as operacaoRibercred } from "../store/modules/operacaoRibercred/actions";
import { setPermitions as pendenciaInstituicao } from "../store/modules/pendenciaInstituicao/actions";
import { setPermitions as pendenciaRibercred } from "../store/modules/pendenciaRibercred/actions";
import { setPermitions as produtosInstituicoes } from "../store/modules/produtosInstituicoes/actions";
import { setPermitions as produtosRibercred } from "../store/modules/produtosRibercred/actions";
import { setPermitions as propostas } from "../store/modules/propostas/actions";
import { setPermitionsTabs as propostasTabs } from "../store/modules/propostas/actions";
import { setPermitions as statusInstituicao } from "../store/modules/statusInstituicao/actions";
import { setPermitions as statusRibercred } from "../store/modules/statusRibercred/actions";
import { setPermitions as tipoComissao } from "../store/modules/tipoComissao/actions";
import { setPermitions as tipoConvenio } from "../store/modules/tipoConvenio/actions";
import { setPermitions as tipoDocumento } from "../store/modules/tipoDocumento/actions";
import { setPermitions as tipoFormalizacaoInstituicao } from "../store/modules/tipoFormalizacaoInstituicao/actions";
import { setPermitions as tipoFormalizacaoRibercred } from "../store/modules/tipoFormalizacaoRibercred/actions";
import { setPermitions as tipoParceria } from "../store/modules/tipoParceria/actions";
import { setPermitions as uploadComissao } from "../store/modules/uploadComissao/actions";
import { setPermitions as usuario } from "../store/modules/usuario/actions";
import { setPermitions as perfilAcesso } from "../store/modules/perfilAcesso/actions";
import { setPermitions as dash } from "../store/modules/dashboard/actions";
import { setPermitionsTables as dashTables } from "../store/modules/dashboard/actions";
import { setPermitions as perfilCliente } from "../store/modules/perfilCliente/actions";
import { setPermitions as aceiteCp } from "../store/modules/aceiteCp/actions";
import { setPermitions as conferenciaCp } from "../store/modules/conferenciaCp/actions";
import { setPermitions as vinculoLoja } from "../store/modules/vinculoLoja/actions";
import { setPermitions as agrupamentoPropostas } from "../store/modules/agrupamentoProposta/actions";
import { setPermitions as agrupamentoPropostasCentroCusto } from "../store/modules/agrupamentoPropostaCentroCusto/actions";
import { setPermitions as antecipacaoContasReceber } from "../store/modules/antecipacaoContasReceber/actions";
import { setPermitions as conferenciaCr } from "../store/modules/conferenciaCr/actions";
import { setPermitionsContaGerencial as contaGerencial } from "../store/modules/contaGerencial/actions";

export const telasSistema = [
  {
    id: "5B2EA770-B619-45AF-AF0B-381F435557EC",
    nomeTela: "Grupos",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      grupos(value);
    },
  },
  {
    id: "1422CFFE-FFB1-411F-9702-9771AB198F64",
    nomeTela: "Antecipação CR",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      antecipacaoContasReceber(value);
    },
  },
  {
    id: "62533669-3BBD-41B0-953F-E0E433DC9D0B",
    nomeTela: "Perfil do Cliente",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      perfilCliente(value);
    },
  },
  {
    id: "DD3140A5-33A3-49DE-A0BF-9C0326F0F607",
    nomeTela: "Personas",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      personas(value);
    },
  },
  {
    id: "3AA128E3-2923-4060-AB5E-35D6E350FDB6",
    nomeTela: "Layout Parametrização",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      layoutParametrizacao(value);
    },
  },
  {
    id: "37626604-730F-4D13-BAAD-3660EE99F770",
    nomeTela: "Conta Gerencial",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      contaGerencial(value);
    },
  },
  {
    id: "74044A0F-0BA0-4213-A69F-7E0938FAEAF4",
    nomeTela: "Vinculo Centro de Custo",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      vinculoCentroDeCusto(value);
    },
  },
  {
    id: "B520BD45-C493-438A-A318-D6CE3EDA4875",
    nomeTela: "Centro de Custo",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      centroDeCusto(value);
    },
  },
  {
    id: "8C5A8481-864B-40CF-8F7B-99CDE8E80F39",
    nomeTela: "Segurança - Usuários",
    options: {
      criar: true,
      deletar: true,
      desbloquear: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      usuario(value);
    },
  },
  {
    id: "C55037E7-CEE4-4CB0-83E7-6C092A97A79D",
    nomeTela: "Perfil de Acesso",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      perfilAcesso(value);
    },
  },
  {
    id: "3128C124-1A46-4BA4-9CCF-62B4A2513EF5",
    nomeTela: "Dashboard Campanhas e Metas",
    options: {
      criar: false,
    },
    actions: (value) => {
      dash("campanha", value);
    },
  },
  {
    id: "D6A03C6D-953F-451D-86AA-E76E6C7D82C4",
    nomeTela: "Dashboard Provisionamento",
    options: {
      criar: false,
    },
    actions: (value) => {
      dash("previsao", value);
    },
  },
  {
    id: "6980DE4B-6980-4FE2-9951-857A8B336307",
    nomeTela: "Dashboard Esteira Produção",
    options: {
      criar: false,
    },
    actions: (value) => {
      dash("esteira", value);
    },
  },
  {
    id: "FF336E87-3F19-430C-BCC1-4A8AAFB0D8C9",
    nomeTela: "Dashboard Formalização",
    options: {
      criar: false,
    },
    actions: (value) => {
      dash("formalizacao", value);
    },
  },
  {
    id: "41BBC09F-D982-43E4-BE89-451D56F96F6D",
    nomeTela: "Dashboard Produção",
    options: {
      criar: false,
      filtros: false,
    },
    actions: (value) => {
      dash("producao", value);
    },
  },
  {
    id: "CE385C1C-495F-4636-B947-CD68F9BF899D",
    nomeTela: "Dashboard Produção - Tabela Canal",
    options: {
      criar: false,
      filtros: false,
    },
    actions: (value) => {
      dashTables("producao", "tabelaCanal", value);
    },
  },
  {
    id: "2EB55AF0-A1F1-4CF4-A256-6D5083A7415B",
    nomeTela: "Dashboard Receita",
    options: {
      criar: false,
    },
    actions: (value) => {
      dash("receita", value);
    },
  },
  {
    id: "6A0DA6D9-21C7-45E3-BFF9-3005829E552F",
    nomeTela: "Produto Ribercred",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      produtosRibercred(value);
    },
  },
  {
    id: "0868FE11-9E75-489C-9A48-94A217B6639C",
    nomeTela: "Produto Instituição",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      produtosInstituicoes(value);
    },
  },
  {
    id: "59711D78-556D-469F-A641-04FA9FD6AA04",
    nomeTela: "Operação Ribercred",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      operacaoRibercred(value);
    },
  },
  {
    id: "E6F05E23-69A2-4BB6-8C80-B08D2188D43F",
    nomeTela: "Operação Instituição",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      operacaoInstituicao(value);
    },
  },
  {
    id: "F0AEB4B4-9060-40B0-9E06-6C8B23FAE5E5",
    nomeTela: "Convênio Ribercred",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      conveniosRibercred(value);
    },
  },
  {
    id: "C03207C2-1843-4E45-872A-88C2360E69E6",
    nomeTela: "Convênio Instituição",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      convenioInstituicao(value);
    },
  },
  {
    id: "2C89925A-3543-49A7-B44F-DA26F75D83AF",
    nomeTela: "Tipo de Convênio",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      tipoConvenio(value);
    },
  },
  {
    id: "B27A6DCD-2463-4ED0-88DB-A9FFC49EFC46",
    nomeTela: "Contas a Receber",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      contasReceber(value);
    },
  },
  {
    id: "D5C6EC1B-9F08-4252-BFE4-FB7FC2786B21",
    nomeTela: "Contas a Pagar",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      contasAPagar(value);
    },
  },
  {
    id: "057DF3F2-1672-41B6-B459-8088676A3E1F",
    nomeTela: "Aceite CP",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      aceiteCp(value);
    },
  },
  {
    id: "FD4F9987-89A8-4FBC-BB85-0B7CEBF7C391",
    nomeTela: "Conferência CP",
    options: {
      criar: true,
      autorizar: true,
      estornar: true,
    },
    actions: (value) => {
      conferenciaCp(value);
    },
  },
  {
    id: "E94A9B51-5181-4022-93F4-CD51053FE168",
    nomeTela: "Conferência CR",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      conferenciaCr(value);
    },
  },
  {
    id: "B2C1117A-E0E3-46E9-86D2-3B0A3A67AD66",
    nomeTela: "Tabela de Comissão",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      comissao(value);
    },
  },
  {
    id: "38A763C2-3FEA-4FA4-9C05-DBA154B3D0D6",
    nomeTela: "Upload de Planilhas",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: true,
    },
    actions: (value) => {
      uploadComissao(value);
    },
  },
  {
    id: "D7191568-5BA9-4844-9E68-CFD18C9BD8E8",
    nomeTela: "Campanha de Premiação",
    options: {
      criar: true,
      deletar: true,
      autorizar: true,
      estornar: false,
    },
    actions: (value) => {
      campanhaPremiacao(value);
    },
  },
  {
    id: "24BCE9EB-9C97-4410-A60C-C7A7C79DDDC6",
    nomeTela: "Tipo de Comissão",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      tipoComissao(value);
    },
  },
  {
    id: "D0A70294-141A-4FB2-BFEC-7261B9777B8D",
    nomeTela: "Status Ribercred",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      statusRibercred(value);
    },
  },
  {
    id: "1F831FF9-7DA7-4368-BC17-F4E23C0342F0",
    nomeTela: "Status Instituição",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      statusInstituicao(value);
    },
  },
  {
    id: "EBE5B00C-6F53-4E3F-9700-55A95B52DFF3",
    nomeTela: "Hierarquias",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      hierarquia(value);
    },
  },
  {
    id: "7DFEE358-0B13-430B-B11A-95B867836207",
    nomeTela: "Consistências",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      inconsistencias(value);
    },
  },
  {
    id: "3759DFE0-3252-4F6F-851D-4FEE7D7B6512",
    nomeTela: "Tipo de Parceria",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      tipoParceria(value);
    },
  },
  {
    id: "2422801B-EC35-4B8E-80C4-7016CB762B47",
    nomeTela: "Tipo de Documento",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      tipoDocumento(value);
    },
  },
  {
    id: "3C563F88-499A-4831-A355-5DCA31D25145",
    nomeTela: "Agrupamento Comissão Recebida",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      agrupamentoPropostas(value);
    },
  },
  {
    id: "83328FE4-444F-4160-BE94-585308571D17",
    nomeTela: "Agrupamento Comissão Recebida Centro Custo",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      agrupamentoPropostasCentroCusto(value);
    },
  },
  {
    id: "E387FBB0-5BB0-410C-9FEF-37D356085BDD",
    nomeTela: "Divisão de Comissão",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      divisaoComissao(value);
    },
  },
  {
    id: "A9F6CA34-417E-4F8E-B3C1-DEB47FAB1134",
    nomeTela: "Propostas",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      propostas(value);
    },
  },
  {
    id: "383F6EBB-982A-4D85-842D-E8E1821EF3B4",
    nomeTela: "Propostas - Comissões",
    options: {
      criar: false,
    },
    actions: (value) => {
      propostasTabs("comissao", value);
    },
  },
  {
    id: "DF8062F5-101C-4ADA-A6A3-AA11B169D6E0",
    nomeTela: "Propostas - Contas a Pagar",
    options: {
      criar: false,
    },
    actions: (value) => {
      propostasTabs("cp", value);
    },
  },
  {
    id: "FB9C4DCD-0DC7-4912-B0FB-BDB0A14385B2",
    nomeTela: "Propostas - Contas a Receber",
    options: {
      criar: false,
    },
    actions: (value) => {
      propostasTabs("cr", value);
    },
  },
  {
    id: "813BCE5F-5A54-4F75-B7EA-AEEF52285D52",
    nomeTela: "Formalização Matriz",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      formalizacaoMatriz(value);
    },
  },
  {
    id: "B8FB20C5-A8E5-4BE7-8338-7134F2E606C4",
    nomeTela: "Formalização Loja",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      formalizacaoLoja(value);
    },
  },
  {
    id: "F08AD59C-8F7A-4ACB-AC7A-0D96AD4B2562",
    nomeTela: "Tipo Formalização Ribercred",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      tipoFormalizacaoRibercred(value);
    },
  },
  {
    id: "5A44A07D-967F-41DB-8D80-E042C057F195",
    nomeTela: "Tipo Formalização Instituição",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      tipoFormalizacaoInstituicao(value);
    },
  },
  {
    id: "05D054C0-DB71-4B74-8266-E883F4711FC9",
    nomeTela: "Inconsistência Sistema Produção",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      inconsistenciaProducao(value);
    },
  },
  {
    id: "10085F83-611D-4E98-847D-345B5354F1DA",
    nomeTela: "Inconsistência Sistema Comissão",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      inconsistenciaComissao(value);
    },
  },
  {
    id: "DA9EF765-AB27-41BE-9C14-ED1937EC6A66",
    nomeTela: "Inconsistência Sistema Comissão Recebida",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      inconsistenciaComissaoRecebida(value);
    },
  },
  {
    id: "E66EA69E-1C4E-499A-A52A-69B0333C62C5",
    nomeTela: "Inconsistência Sistema Proposta",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      inconsistenciaProposta(value);
    },
  },
  {
    id: "42CFE5F2-D0D7-41A3-AA86-83AEFEC51998",
    nomeTela: "Inconsistência Sistema Formalização",
    options: {
      criar: true,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      inconsistenciaFormalizacao(value);
    },
  },
  {
    id: "0E92F4C0-7959-4E83-8634-7B56EA59E1D5",
    nomeTela: "Pendência Ribecred",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      pendenciaRibercred(value);
    },
  },
  {
    id: "C9707568-05EA-4B27-B072-6A80A27B76EE",
    nomeTela: "Pendência Instituição",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      pendenciaInstituicao(value);
    },
  },
  {
    id: "D2D23083-83F2-4CE2-988E-077127F0D9DF",
    nomeTela: "Parâmetros de Automação",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "D8F08E3B-50A4-4CB0-A9F5-0E92D511CFA2",
    nomeTela: "Vinculo Loja",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: (value) => {
      vinculoLoja(value);
    },
  },
  {
    id: "A11D0BA1-5592-48B2-8B65-1AA08B40D14A",
    nomeTela: "Carteira - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "B6CCA92E-C365-4BDF-8193-1E5637276801",
    nomeTela: "Grupos - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "7BFF2E0C-F690-440E-B801-259F62FC7995",
    nomeTela: "Upload de arquivos - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "5B28EB9B-0E74-4585-A926-348435D00431",
    nomeTela: "Parametrização de produtos - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "A079245E-CCD8-4079-9BB4-46B9A5DB5C9D",
    nomeTela: "Indicadores - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "C554D421-7AA8-492D-87D6-D7A4415CE7AF",
    nomeTela: "Indicação - CRM",
    options: {
      criar: true,
      deletar: true,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "F5DE3881-FD49-4D9A-9D90-4AC76D20F57E",
    nomeTela: "Início - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "7B4BD31A-4E65-4282-8D75-7475C9E4CEEB",
    nomeTela: "Blacklist - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "CB19BCDD-4DA5-4969-B22C-92DBB41F506F",
    nomeTela: "Agenda - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "897D3A31-2AD3-4707-9F1B-97FF1EA0F1B3",
    nomeTela: "Parametrização da tabulação - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "20726565-8C28-406D-9CA3-A200037E2E84",
    nomeTela: "Histórico - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "CAD85BDD-B1F6-4701-8BA2-AD8221AB9DCA",
    nomeTela: "Credenciais BMG - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "2A933569-176A-4AE4-864F-CCB71761F754",
    nomeTela: "Produção - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "327B9C82-0622-45EA-82E9-E326E96F721F",
    nomeTela: "Backoffice - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "76DA3E3E-18A9-4245-A2E0-E807D427912C",
    nomeTela: "Atendimento - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
  {
    id: "3E99D1C0-9FC3-4654-8ECE-F1473F9E136F",
    nomeTela: "Campanhas - CRM",
    options: {
      criar: false,
      deletar: false,
      autorizar: false,
      estornar: false,
    },
    actions: () => {},
  },
];
