import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import * as parametrosController from "../../../../../controllers/personasController/parametrosController";
import * as actions from "../../../../../store/modules/personas/parametros_store/actions";
import { Select } from "../../../../../components/Inputs/Input";
import SeletorIntegracao from "./components/SeletorIntegracao";
import { Col, FormGroup, Label } from "reactstrap";

const Parametros = () => {
  const { id_integracao, tipoIntegracao } = useSelector((state) => state.personasParametros);
  const { tipoLoja } = useSelector((state) => state.personasPapeis);
  const { tab } = useSelector((state) => state.personas);

  useEffect(() => {
    actions.cleanAll();
    if (tab === '7') {
      parametrosController.getTipoIntegracao();
      parametrosController.getParametrosDePara();
    }
  }, [tab]);

  useEffect(() => {
    if (tab === '7' && tipoLoja.id && id_integracao && id_integracao !== '0') {
      switch (id_integracao) {
        case 1:
          parametrosController.getCredencial(tipoLoja.id);
          break;
        case 7:
          parametrosController.GetByLojaId(tipoLoja.id)
          break;
        default:
          break;
      }
    }
  }, [tipoLoja.id, tab, id_integracao]);

  return (
    <>
      <Col style={{ margin: '25px 0 0 25px', padding: '0px' }}>
        <p style={{ fontSize: "18px", color: 'var(--color-preto)' }}>Selecione a integração</p>
      </Col>
      <FormGroup style={{ width: "230px", margin: '25px 0 0 25px' }}>
        <Label for="integracoesSelect">Integrações</Label>
        <Select style={{ margin: '25px 0 0 25px' }}
          first="Selecione"
          value={{ value: id_integracao }}
          onChange={e => actions.setTipoIntegracao(e.value)}
          select={{ value: "tipo", label: "descricao" }}
          options={tipoIntegracao}
          isSearchable={true}
        />
      </FormGroup >

      <SeletorIntegracao id_integracao={id_integracao} />

    </>
  );
};

export default Parametros;
