import React, { useEffect, useMemo, useState } from "react";
import { usePagination, useTable } from "react-table";
import ReactPaginate from "react-paginate";
import { useSelector } from "react-redux";
import {
  Modal,
  ModalBody,
  Col,
  Button,
  Input,
  Label,
  Row,
  Navbar,
  FormGroup,
} from "reactstrap";

import { InputCpfCnpj, Select } from "../../../../components/Inputs/Input";
import * as actions from "../../../../store/modules/dashboard/campanha/actions";
import * as controller from "../../../../controllers/dashboardController/campanhaController";
import { maskCPF } from "../../../../util/masks";
import LoadingIcon from "../../../../components/Loading/LoadingIcon";
import { debounce } from "../../../../util/debounce";

const debounceFunc = debounce((func) => {
  func()
}, 300)

export default function ModalConsultores({ isOpen, toggle }) {
  const {
    modalConsultores,
    consultores,
    getAllConsultores,
    hierarquias,
    canais,
    loadingConsultores,
    statusFuncionario
  } = useSelector((state) => state.dashboardCampanha);

  const columns = useMemo(
    () => [
      {
        Header: "CPF",
      },
      {
        Header: "NOME",
      },
      {
        Header: "STATUS",
      },
    ],
    []
  );
  const data = consultores;

  const { page, gotoPage, state, setPageSize } = useTable(
    {
      columns,
      data,
    },
    usePagination
  );

  const { pageSize } = state;
  const [rows, setRows] = useState(0);
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  useEffect(() => {
    setRows(page.length);
  }, [page]);

  useEffect(() => {
    controller.getCanal();
  }, []);

  useEffect(() => {
    if (modalConsultores.canalId !== "0") {
      controller.getHierarquias(modalConsultores.canalId);
    } else {
      actions.setModalConsultores("hierarquiaId", "0");
      actions.setHierarquias([]);
    }
  }, [modalConsultores.canalId]);

  const handlePageClick = (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);

    controller.getConsultores(modalConsultores, pageSelected + 1, pageSize);
  };

  function handleInitialPage(pageSize) {
    const pageSelected = 0;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    controller.getConsultores(modalConsultores, pageSelected + 1, pageSize);
  }


  function onChangeItem(value, campo) {
    actions.setFiltros(campo, value);
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      controller.getConsultores(modalConsultores, 1, pageSize);
    }
  }

  function handleLimpar() {
    actions.setConsultores([]);
    actions.setModalConsultores("canalId", "0");
    actions.setModalConsultores("hierarquiaId", "0");
    actions.setModalConsultores("cpf", "");
    actions.setModalConsultores("nome", "");
    actions.setModalConsultores("status", true);
  }

  useEffect(() => {
    if(isOpen) {
      debounceFunc(() => {
        controller.getConsultores(modalConsultores, 1, pageSize);
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalConsultores])

  useEffect(() => {
    if(!isOpen) {
      handleLimpar()
    }
  }, [isOpen]);


  return (
    <>
      <Modal
        className="text-center"
        isOpen={isOpen}
        toggle={toggle}
        backdrop={true}
        size="lg"
      >
        <Navbar
          expand="md"
          style={{
            width: "100%",
            height: "40px",
            background: "var(--color-gradiente-verde-claro)",
            marginBottom: "20px",
          }}
        >
          <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2">
            Pesquisa de Hierarquia
          </h5>
        </Navbar>
        <ModalBody>
          <Row className="align-items-start">
            <Col md={3}>
              <FormGroup>
                <Label>Canal</Label>
                <Select
                  id="canalSelect"
                  first="Selecione"
                  value={{ value: modalConsultores.canalId }}
                  onChange={(e) => {
                    actions.setModalConsultores("canalId", e.value);
                  }}
                  select={{ value: "id", label: "nome" }}
                  options={canais}
                  isSearchable
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup className="mb-3">
                <Label htmlFor="hierarquiaSelect">Hierarquia</Label>
                <Select
                  id="hierarquiaSelect"
                  first="Selecione"
                  value={{ value: modalConsultores.hierarquiaId }}
                  onChange={(e) => {
                    actions.setModalConsultores("hierarquiaId", e.value);
                  }}
                  select={{ value: "id", label: "descricao" }}
                  options={hierarquias}
                  isSearchable
                />
              </FormGroup>
            </Col>

            <Col md={3}>
              <FormGroup className="mb-3">
                <Label htmlFor="cpfInput">CPF</Label>
                <InputCpfCnpj
                  id="cpfInput"
                  type="text"
                  placeholder="Digite aqui..."
                  tipo="cpf"
                  value={modalConsultores.cpf}
                  onChange={(e) =>
                    actions.setModalConsultores("cpf", e.target.value)
                  }
                  onKeyPress={(e) => handleKeyPress(e)}
                  maxLength="18"
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="mb-3">
                <Label htmlFor="nomeInput">Nome</Label>
                <Input
                  id="nomeInput"
                  type="text"
                  placeholder="Digite aqui..."
                  value={modalConsultores.nome}
                  onChange={(e) =>
                    actions.setModalConsultores("nome", e.target.value)
                  }
                  onKeyPress={(e) => handleKeyPress(e)}
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup className="mb-3">
                <Label htmlFor="statusSelect">Status</Label>
                <Select
                  id="statusSelect"
                  first="Selecione"
                  value={{ value: modalConsultores.status }}
                  onChange={(e) => {
                    actions.setModalConsultores("status", e.value);
                  }}
                  select={{ value: "id", label: "descricao" }}
                  options={statusFuncionario}
                  isSearchable
                />
              </FormGroup>
            </Col>
          </Row>
          <fieldset
            className={
              consultores.length > 0
                ? "fieldset-socio"
                : "fieldset-socio-no-results"
            }
          >
            <legend style={{ textAlign: "left", fontSize: "20px" }}>
              Resultados
            </legend>
            {loadingConsultores && <LoadingIcon />}
            <table>
              <thead>
                <tr>
                  <th style={{ width: "100px" }}>CPF</th>
                  <th style={{ width: "150px" }}>Nome</th>
                  <th style={{ width: "150px" }}>Status</th>
                </tr>
              </thead>
              <tbody>
                {consultores &&
                  consultores
                    .slice()
                    .sort((a, b) => a.nome.localeCompare(b.nome))
                    .map((row) => {
                      return (
                        <tr
                          key={row.id}
                          onDoubleClick={() => {
                            onChangeItem(row.nome, "consultor");
                            onChangeItem(row.id, "consultorId");

                            toggle();
                          }}
                        >
                          <td>{maskCPF(row.cpfCnpj)}</td>
                          <td>{row.nome}</td>
                          <td>{row.statusFuncionario ? "Ativo" : "Inativo"}</td>
                        </tr>
                      );
                    })}
              </tbody>
            </table>
            <br />
          </fieldset>
          {consultores && (
            <Row className="pt-4">
              <Col md={3}>
                <span style={{ color: "var(--color-cinza-medio)" }}>
                  Mostrando {rows} de {getAllConsultores.quantidadeTotal}
                </span>
              </Col>
              <Col md={8} className="float-right">
                <Row>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    initialPage={0}
                    pageCount={getAllConsultores.totalPaginas}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={(e) => handlePageClick(e)}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={initialPage ? 0 : selectedPage}
                  />
                  <Input
                    type="select"
                    className="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      handleInitialPage(e.target.value);
                    }}
                  >
                    {[10, 25, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                </Row>
              </Col>
            </Row>
          )}
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => handleLimpar()}
            className="mr-4"
            style={{
              width: "150px",
              background: "var(--color-branco)",
              borderColor: "var(--color-laranja)",
              color: "var(--color-laranja)",
              borderRadius: "7px",
            }}
          >
            Limpar
          </Button>
          <Button
            onClick={() =>
              controller.getConsultores(
                { ...modalConsultores },
                1,
                pageSize
              )
            }
            color="danger"
            className="purpleButton"
            style={{ width: "150px" }}
          >
            Pesquisar
          </Button>
        </div>
      </Modal>
    </>
  );
}
