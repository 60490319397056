import { STORE_ID, TYPES } from "./types";

const INITIAL_STATE = {
  search: {
    campanhaPremiacaoId: "0",
    empresaId: "0",
    tipoRegraId: "0",
    dataAceito: "",
    sistemaId: "0",
    statusId: "0",
  },
  item: {
    id: "",
    sistemaId: "0",
    tipoRegraId: "",
    valor: 0,
  },
  contasPagarAgrupado: [],
  contaPagarAgrupadoItens: [],
  optionsSelect: {
    campanhas: [],
    empresas: [],
    tiposPagamento: [],
    sistemas: [],
    status: [],
  },
  pagination: {
    quantidade: 0,
    quantidadeTotal: 0,
    totalPaginas: 0,
  },
  paginationItens: {
    quantidade: 0,
    quantidadeTotal: 0,
    totalPaginas: 0,
  },
  download: {
    arquivoBase64: "",
    nomeArquivo: "",
  },
  permitions: {
    criar: false,
    autorizar: false,
    estornar: false,
  },
};

export default function conferenciaCp(state = INITIAL_STATE, action) {
  switch (action.storeId) {
    case STORE_ID:
      switch (action.type) {
        case TYPES.SET_PERMITIONS:
          return { ...state, permitions: action.value };
        case TYPES.SET_OPTIONS_SELECT:
          return {
            ...state,
            optionsSelect: {
              ...state.optionsSelect,
              [action.field]: action.value,
            },
          };
        case TYPES.SET_SEARCH:
          return {
            ...state,
            search: {
              ...state.search,
              [action.field]: action.value,
            },
          };
        case TYPES.CLEAN_SEARCH:
          return { ...state, search: INITIAL_STATE.search };
        case TYPES.SET_CONTAS_PAGAR_AGRUPADO:
          return { ...state, contasPagarAgrupado: action.value };
        case TYPES.SET_CONTA_PAGAR_AGRUPADO_ITENS:
          return { ...state, contaPagarAgrupadoItens: action.value };
        case TYPES.SET_PAGINATION:
          return { ...state, pagination: action.value };
        case TYPES.SET_ITEM:
          return { ...state, item: action.value };
        case TYPES.CLEAN_ITEM:
          return { ...state, item: INITIAL_STATE.item };
        case TYPES.SET_ITEM_FIELD:
          return { ...state, item: {
            ...state.item,
            [action.field]: action.value
          }
        };
        case TYPES.SET_DOWNLOAD:
          return { ...state, download: action.value };
        default:
          return { ...state };
      }
    default:
      return { ...state };
  }
}
