import { STORE_ID, TYPES } from "./types";
import store from "../../index";

function dispatch(data) {
  data = { ...data, storeId: STORE_ID };
  store.dispatch(data);
}

export function setPermitions(value) {
  dispatch({
    type: TYPES.SET_PERMITIONS,
    value,
  });
}

export function cleanSearch() {
  dispatch({
    type: TYPES.CLEAN_SEARCH
  });
}

export function setOptionsSelect(field, value) {
  dispatch({
    type: TYPES.SET_OPTIONS_SELECT,
    field,
    value
  });
}

export function setSearch(field, value) {
  dispatch({
    type: TYPES.SET_SEARCH,
    field,
    value
  });
}

export function setContasPagarAgrupado(value) {
  dispatch({
    type: TYPES.SET_CONTAS_PAGAR_AGRUPADO,
    value
  });
}

export function setContaPagarAgrupadoItens(value) {
  dispatch({
    type: TYPES.SET_CONTA_PAGAR_AGRUPADO_ITENS,
    value
  });
}

export function setPagination(value) {
  dispatch({
    type: TYPES.SET_PAGINATION,
    value
  });
}

export function setItem(value) {
  dispatch({
    type: TYPES.SET_ITEM,
    value
  });
}

export function cleanItem() {
  dispatch({
    type: TYPES.CLEAN_ITEM
  });
}

export function setItemField(value, field) {
  dispatch({
    type: TYPES.SET_ITEM_FIELD,
    value,
    field
  });
}

export function setDownload(value) {
  dispatch({
    type: TYPES.SET_DOWNLOAD,
    value
  });
}