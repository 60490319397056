const STORE_ID = "PERSONAS_PARAMETROS";

const TYPES = {
  SET_CREDENCIAIS: "SET_CREDENCIAIS",
  SET_CREDENCIAIS_FIELDS: "SET_CREDENCIAIS_FIELDS",
  SET_ITEM: "SET_ITEM",
  CLEAN_ITEM: "CLEAN_ITEM",
  SET_ITEM_FIELD: "SET_ITEM_FIELD",
  SET_INTEGRACAO: "SET_INTEGRACAO",
  SET_INTEGRACOES: "SET_INTEGRACOES",
  SET_PLATAFORMAS: "SET_PLATAFORMAS",
  CLEAN_ALL: "CLEAN_ALL",
  SET_TIPO_INTEGRACAO: "SET_TIPO_INTEGRACAO",
  SET_PARAMETROS_DE_PARA: "SET_PARAMETROS_DE_PARA",
  SET_SEE: "SET_SEE",
  CLEAN_REGISTER: "CLEAN_REGISTER",
};

export { STORE_ID, TYPES };
