import api from "../../util/api";
import * as personaActions from "../../store/modules/personas/personas_store/actions";
import * as informacoesActions from "../../store/modules/personas/informacoes_store/actions";
import * as enderecosActions from "../../store/modules/personas/endereco_store/actions";
import * as contatosActions from "../../store/modules/personas/contato_store/actions";
import * as documentosActions from "../../store/modules/personas/documentacao_store/actions";
import * as papeisActions from "../../store/modules/personas/papeis_store/actions";
import * as contaActions from "../../store/modules/personas/conta_corrente_store/actions";
import * as instituicaoFinanceiraActions from "../../store/modules/personas/instituicaoFinanceira/actions";
import { notifySuccess, notifyWarn } from "../../components/message/message";
import { setLoading } from "../../store/modules/globalLoading/actions";
import * as controllerPapeis from "./papeisController";

import {
    removeMaskCPF,
    removeMaskCNPJ,
    maskCNPJ,
    maskCEP,
    maskTelefone,
    maskMoeda,
    removeMaskMoeda,
    maskDate,
    maskCPF,
} from "../../util/masks";
import { ValidateCNPJ, ValidateCPF } from "../../util/validations";

let guidNull = "00000000-0000-0000-0000-000000000000";

export async function get(page, rows, search) {
    let pagination = 1;
    pagination += page;

    let cpfCnpjNoMask =
        search.cpfCnpj !== ""
            ? search.cpfCnpj.length === 14
                ? removeMaskCPF(search.cpfCnpj)
                : removeMaskCNPJ(search.cpfCnpj)
            : "";

    setLoading(true);
    return await api
        .get(
            `Pessoa?cpfCnpj=${cpfCnpjNoMask}&nome=${search.razaoSocialNome
            }&dataNascimento=${search.criacaoNascimento}&ramal=${search.ramal}${search.canalId !== '0' ? `&canalId=${search.canalId}` : ''}${search.tipoLojaId !== '0' ? `&tipoLojaId=${search.tipoLojaId}` : ''}&cidade=${search.cidade}&uf=${search.uf === "UF" ? "" : search.uf
            }&pagina=${pagination}&registros=${rows}&limiteMaximoRegistros=100${search.codigoPapel !== "0" ? `&papel=${search.codigoPapel}` : ""
            }&nomeUsuario=${search.usuarioInstituicao}&codigoLojaBanco=${search.codigoLojaBanco}`
        )
        .then((response) => {
            if (!response.success) {
                return;
            }
            setLoading(false);

            const register = response.content.registros;
            const data = [];

            register.map((element) => {
                const cidades = [];
                const estados = [];
                //const papeis = [];

                if (element.enderecos.length > 0) {
                    element.enderecos.forEach((endereco) => {
                        cidades.push(endereco.cidade);
                        estados.push(endereco.estado.sigla);
                    });
                }

                let cpfCnpjFormated
                if (element.cpfCnpj.length === 14) {
                    cpfCnpjFormated = maskCNPJ(element.cpfCnpj)
                } else {
                    cpfCnpjFormated = maskCPF(element.cpfCnpj, true);
                }


                return data.push({
                    id: element.id,
                    cpfCnpj: cpfCnpjFormated,
                    nome: element.nome,
                    dataNascimento: element.dataNascimento,
                    cidade: cidades.length > 0 ? cidades[0] : "",
                    uf: estados.length > 0 ? estados[0] : "",
                }); //Alterar quando flagPrincipal funcionar
            });
            personaActions.getItems(data);
            personaActions.getAllItems({
                quantidade: response.content.quantidade,
                quantidadeTotal: response.content.quantidadeTotal,
                totalPaginas: response.content.totalPaginas,
                tamanhoPagina: response.content.tamanhoPagina
            });
        }).finally(() => {
            setLoading(false);
        });
}

export async function getComNome(page, rows, search) {
    let pagination = 1;
    pagination += page;

    let cpfCnpjNoMask =
        search.cpfCnpj !== ""
            ? search.cpfCnpj.length === 14
                ? removeMaskCPF(search.cpfCnpj)
                : removeMaskCNPJ(search.cpfCnpj)
            : "";

    setLoading(true);
    return await api
        .get(
            `Pessoa/busca-com-nome/${search.razaoSocialNome}?cpfCnpj=${cpfCnpjNoMask}&nome=${search.razaoSocialNome
            }&dataNascimento=${search.criacaoNascimento}&ramal=${search.ramal}${search.canalId !== '0' ? `&canalId=${search.canalId}` : ''}${search.tipoLojaId !== '0' ? `&tipoLojaId=${search.tipoLojaId}` : ''}&cidade=${search.cidade}&uf=${search.uf === "UF" ? "" : search.uf
            }&pagina=${pagination}&registros=${rows}&limiteMaximoRegistros=100${search.codigoPapel !== "0" ? `&papel=${search.codigoPapel}` : ""
            }&nomeUsuario=${search.usuarioInstituicao}&codigoLojaBanco=${search.codigoLojaBanco}`
        )
        .then((response) => {
            if (!response.success) {
                setLoading(false)
                return;
            }
            setLoading(false);

            const register = response.content.registros;
            const data = [];

            register.map((element) => {
                const cidades = [];
                const estados = [];

                if (element.enderecos.length > 0) {
                    element.enderecos.forEach((endereco) => {
                        cidades.push(endereco.cidade);
                        estados.push(endereco.estado.sigla);
                    });
                }

                let cpfCnpjFormated
                if (element.cpfCnpj.length === 14) {
                    cpfCnpjFormated = maskCNPJ(element.cpfCnpj)
                } else {
                    cpfCnpjFormated = maskCPF(element.cpfCnpj, true);
                }


                return data.push({
                    id: element.id,
                    cpfCnpj: cpfCnpjFormated,
                    nome: element.nome,
                    dataNascimento: element.dataNascimento,
                    cidade: cidades.length > 0 ? cidades[0] : "",
                    uf: estados.length > 0 ? estados[0] : "",
                });
            });
            personaActions.getItems(data);
            personaActions.getAllItems({
                quantidade: response.content.quantidade,
                quantidadeTotal: response.content.quantidadeTotal,
                totalPaginas: response.content.totalPaginas,
                tamanhoPagina: response.content.tamanhoPagina
            });
        });
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function getEstados() {
    const data = await api.get("estados");

    if (!data.success) {
        return;
    }

    personaActions.getItemsEstados(data.content);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function getCanais() {
    const data = await api.get("Canal");

    if (!data.success) {
        return;
    }

    personaActions.getCanais(data.content);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function getTiposLoja() {
    const data = await api.get("TipoLoja");

    if (!data.success) {
        return;
    }

    personaActions.getTiposLoja(data.content);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function getNascimentos() {
    const data = await api.get("estados");

    if (!data.success) {
        return;
    }

    personaActions.getItemsNascimentos(data.content.registros);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function getPapeis() {
    const data = await api.get("papel");

    if (!data.success) {
        return;
    }

    personaActions.getItemsPapeis(data.content);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function getById(id) {
    setLoading(true)
    const data = await api.get(`pessoa/${id}`);
    setLoading(false)
    if (!data.success) {
        return;
    }

    if (data.content.viaSerpro) {
        informacoesActions.setSerpro(true)
    }

    if (!data.content.flagJuridica) {
        let informacaoPf = {
            id: data.content.id,
            nome: data.content.nome,
            cpf: data.content.cpfCnpj,
            rg: data.content.rg,
            orgaoRgId: data.content.rgOrgaoEmissor.id,
            ufRgId: data.content.estadoOrgaoEmissor.id,
            dataNascimento: maskDate(data.content.dataNascimento, "YYYY-MM-DD"),
            nomeMae: data.content.nomeMae,
            nomePai: data.content.nomePai,
        };

        informacoesActions.setActivePersona(data.content.flagJuridica);
        informacoesActions.item(informacaoPf);
    } else {
        let informacaoPj = {
            id: data.content.id,
            razaoSocial: data.content.nome,
            nomeFantasia: data.content.nomeFantasia,
            isento: data.content.isento,
            cnpj: data.content.cpfCnpj,
            dataCriacao: maskDate(data.content.dataNascimento, "YYYY-MM-DD"),
            inscricaoEstadual: data.content.ie,
            inscricaoMunicipal: data.content.im
        };

        informacoesActions.setActivePersona(data.content.flagJuridica);
        informacoesActions.item(informacaoPj);

        let socios = [];

        data.content.socios.forEach((socio) => {
            socios.push({
                id: socio.id,
                socio: socio.pessoa.nome,
                pessoaSocioId: socio.pessoaSocioId,
            });
        });

        informacoesActions.setSocios(socios);
    }

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    if (!data.content.flagJuridica) {
        if (data.content.cliente) {
            let valorIndividualNoMask
            data.content.cliente.valorRendaIndividual ? valorIndividualNoMask = maskMoeda(data.content.cliente.valorRendaIndividual.toFixed(2)) : valorIndividualNoMask = '';

            papeisActions.clienteCpf({
                id: data.content.cliente.id,
                valorRendaIndividual: valorIndividualNoMask,
                profissao: data.content.cliente.profissao,
            });

            const matriculas = [];
            data.content.cliente.matriculas.forEach((matricula) => {
                matriculas.push({
                    id: matricula.id,
                    numero: matricula.numero,
                    contaCorrente: matricula.contaCorrente,
                    clientePerfil: matricula.clientePerfil,
                    valorRendaIndividual: matricula.valorRendaIndividual
                });
            });

            papeisActions.setMatriculasSelected(matriculas); // CARREGA AS MATRÍCULAS
            papeisActions.setIsClienteCpfActive(true, "isClienteCpfActive");
        }

        if (data.content.funcionario !== null) {
            if (data.content.funcionario.ausencias !== null) {
                papeisActions.setAusencias(data.content.funcionario.ausencias);
            }
            // ABA PAPÉIS - FUNCIONÁRIO / AGENTE/ PARCEIRO

            // CONVERSÃO DAS DATAS PARA O FORMATO BRASILEIRO
            let dataAdmissao = maskDate(data.content.funcionario.dataAdmissao, "YYYY-MM-DD");
            let dataDemissao = data.content.funcionario.dataDemissao !== null ? maskDate(data.content.funcionario.dataDemissao, "YYYY-MM-DD") : "";

            // CARREGA NA TELA AS INFORMAÇÕES DE FUNCIONÁRIO / AGENTE/ PARCEIRO
            papeisActions.funcionario({
                id: data.content.funcionario.id,
                ramal: data.content.funcionario.ramal,
                lojaId: data.content.funcionario.lojaId,
                possuiRamal: data.content.funcionario.ramal ? true : false,
                tipoFuncionarioId: data.content.funcionario.tipoFuncionarioId,
                centroCustoId: data.content.funcionario.centroCustoId,
                codigoFuncionarioERP: data.content.funcionario.codigoFuncionarioErp,
                codigoEmpresa: data.content.funcionario.codigoEmpresa,
                codigoCentroCusto: data.content.funcionario.codigoCentroCusto,
                razaoSocialEmpresa: data.content.funcionario.razaoSocialEmpresa,
                nomeCargo: data.content.funcionario.nomeCargo,
                nomeFuncao: data.content.funcionario.nomeFuncao,
                dataAdmissao: dataAdmissao,
                dataDemissao: dataDemissao,
            });
            // ATIVA A FLAG DE FUNCIONÁRIO / AGENTE/ PARCEIRO
            papeisActions.setIsClienteCpfActive(true, "isFuncionarioActive");
        }

        // ABA PAPÉIS - USUÁRIO DA INSTITUIÇÃO
        if (data.content.usuariosBanco.length > 0) {
            let arrInformacoes = [];
            data.content.usuariosBanco.forEach((instituicao) => {
                let dataInicial =
                    instituicao.dataVigenciaInicial !== null ?
                        maskDate(instituicao.dataVigenciaInicial, "YYYY-MM-DD") : "";
                let dataFinal =
                    instituicao.dataVigenciaFinal !== null ?
                        maskDate(instituicao.dataVigenciaFinal, "YYYY-MM-DD") : "";
                arrInformacoes.push({
                    bancoId: instituicao.bancoId,
                    dataVigenciaInicial: dataInicial,
                    dataVigenciaFinal: dataFinal,
                    id: instituicao.id,
                    usuarioInstituicao: instituicao.usuarioInstituicao,
                    statusUsuario: instituicao.statusUsuario,
                    loja: instituicao.loja.nomeFantasia ? instituicao.loja.nomeFantasia : instituicao.loja.nome,
                    descricaoCentroCusto: instituicao.loja.descricaoCentroCusto ? instituicao.loja.descricaoCentroCusto : "",
                    vinculoCentroCustoId: instituicao.loja.vinculoCentroCustoId ? instituicao.loja.vinculoCentroCustoId : 0,
                    headCount: instituicao.loja.headCount,
                    hierarquia: instituicao.loja.hierarquia ? instituicao.loja.hierarquia : "",
                    hierarquiaId: instituicao.loja.hierarquiaId ? instituicao.loja.hierarquiaId : 0,
                    canal: instituicao.loja.canal ? instituicao.loja.canal : "",
                    canalId: instituicao.loja.canalId ? instituicao.loja.canalId : 0
                });
            });
            papeisActions.userInstituicao(arrInformacoes);
            papeisActions.setIsClienteCpfActive(true, "isUserInstituicaoActive");
        }
    } else {
        // PESSOA JURÍDICA

        let obj = {
            isClienteCnpjActive: false,
            isContaCorrenteJActive: false,
            isMatrizActive: false,
            isInstituicaoActive: false,
        };

        // ABA PAPÉIS - CLIENTE CNPJ
        if (data.content.cliente) {
            let valorFaturamentoMask
            data.content.cliente.valorFaturamento ? valorFaturamentoMask = maskMoeda(data.content.cliente.valorFaturamento.toFixed(2)) : valorFaturamentoMask = '';

            papeisActions.clienteCnpj({
                id: data.content.cliente.id,
                regimeTributarioId: data.content.cliente.regimeTributarioId,
                valorFaturamento: valorFaturamentoMask,
            });
            papeisActions.setIsClienteCnpjActive(true, "isClienteCnpjActive");
            obj.isClienteCnpjActive = true;
        }

        // ABA PAPÉIS - MATRIZ / FILIAL / LOJA / PARCEIRO / SUBSTABELECIDO
        if (data.content.loja !== null) {
            let personas = [];
            let bancos = [];

            papeisActions.tipoLoja({
                id: data.content.loja.id,
                tipoLojaId: data.content.loja.tipoLojaId,
                canalId: data.content.loja.canalId,
                isMatriz: data.content.loja.matriz,
                exibeSimulador: data.content.loja.exibeSimulador,
                matrizId: data.content.loja.lojaMatriz?.id,
                ir: maskMoeda(Number(data.content.loja.ir).toFixed(2)),
                cdEmpresaW3ERP: data.content.loja.codigoEmpresaW3ERP,
                dataInicio: '',
                dataFim: '',
                headCountAtivo: data.content.loja.headCountAtivo,
                dataInicioVigencia: maskDate(data.content.loja.dataInicioVigencia, 'YYYY-MM-DD'),
                dataFimVigencia: maskDate(data.content.loja.dataFimVigencia, 'YYYY-MM-DD'),
                empresa: data.content.loja.empresa
            });
            papeisActions.setCentrosSelected(data.content.loja.centrosCusto); // CENTRO DE CUSTO
            // INSTITUIÇÃO
            data.content.loja.pessoas.forEach((pessoa) => {
                // CONVERSÃO DAS DATAS PRO FORMATO BRASILEIRO
                let dataInicial =
                    pessoa.dataVigenciaInicial !== null ?
                        maskDate(pessoa.dataVigenciaInicial, "YYYY-MM-DD") : "";
                let dataFinal =
                    pessoa.dataVigenciafinal !== null ?
                        maskDate(pessoa.dataVigenciafinal, "YYYY-MM-DD") : "";

                personas.push({
                    dataVigenciaFinal: dataFinal,
                    dataVigenciaInicial: dataInicial,
                    hierarquiaId: pessoa.hierarquiaId,
                    id: pessoa.id,
                    pessoaId: pessoa.pessoaId,
                    pessoa: pessoa.pessoa,
                    headCount: pessoa.headCount,
                    usuarioBanco: pessoa.usuarioBanco,
                    centroCustoId: pessoa?.centroCusto?.id,
                    centroCusto: pessoa?.centroCusto?.descricao,
                });
            });

            // PESSOAS
            data.content.loja.bancos.forEach((banco) => {
                // CONVERSÃO DAS DATAS PRO FORMATO BRASILEIRO
                let dataInicial =
                    banco.dataVigenciaInicial !== null ?
                        maskDate(banco.dataVigenciaInicial, "YYYY-MM-DD") : "";
                let dataFinal =
                    banco.dataVigenciafinal !== null ?
                        maskDate(banco.dataVigenciafinal, "YYYY-MM-DD") : "";

                bancos.push({
                    dataVigenciaFinal: dataFinal,
                    dataVigenciaInicial: dataInicial,
                    bancoId: banco.bancoId,
                    lojaMasterId: banco.lojaMasterId,
                    id: banco.id,
                    tipoParceriaId: banco.tipoParceriaId,
                    possuiComissao: banco.possuiComissao,
                    codigoLojaBanco: banco.codigoLojaBanco?.replace(/^0+/, ''),
                    divisaoComissao: maskMoeda(banco.divisaoComissao),
                });
            });

            personas = personas.reduce((obj, pessoa) => {
                if (!obj[`${pessoa.pessoaId},${pessoa.hierarquiaId}`]) obj[`${pessoa.pessoaId},${pessoa.hierarquiaId}`] = [];
                obj[`${pessoa.pessoaId},${pessoa.hierarquiaId}`].push(pessoa);
                return obj;
            }, {});

            const newPessoas = Object.keys(personas).map(pessoa => {
                const [pessoaId] = pessoa.split(',')
                return {
                    header: {
                        pessoaId,
                        hierarquiaId: personas[pessoa][0].hierarquiaId,
                        headCount: personas[pessoa][0].headCount,
                    },
                    body: personas[pessoa]
                };
            });

            papeisActions.setPersonas(newPessoas)
            papeisActions.setLojasBanco(bancos);
            papeisActions.setIsClienteCnpjActive(true, "isMatrizActive");
        }

        if (data.content.fornecedor) {
            papeisActions.setIsClienteCnpjActive(true, "isFornecedor");
            papeisActions.setFonecedor({
                id: data.content.fornecedor.id,
                codigoFornecedor: data.content.fornecedor.codigoFornecedor
            })
        }

        // INSTITUIÇÃO FINANCEIRA
        if (data.content.instituicaoFinanceira) {
            papeisActions.setInstituicaoFinanceira(
                data.content.instituicaoFinanceira
            );
            instituicaoFinanceiraActions.setInstituicoesFinanceiras(data.content.banco.codigoClienteW3ERP);
            papeisActions.setIsClienteCnpjActive(true, "isInstituicaoActive");
        }
    }
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    let enderecos = []; // ENDEREÇOS

    data.content.enderecos.forEach((endereco) => {
        let cepFormated = maskCEP(endereco.cep);

        enderecos.push({
            bairro: endereco.bairro,
            cep: cepFormated,
            cidade: endereco.cidade,
            complemento: endereco.complemento,
            estado: {
                id: endereco.estado.id,
                nome: endereco.estado.nome,
                sigla: endereco.estado.sigla,
            },
            estadoId: endereco.estadoId,
            id: endereco.id,
            logradouro: endereco.logradouro,
            numero: endereco.numero,
            tipoEnderecoId: endereco.tipoEnderecoId,
            principal: endereco.principal,
            dataUltimaAlteracao: endereco.dataUltimaAlteracao,
            isValidCep: false,
        });
    });

    enderecosActions.setEnderecos(enderecos);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    let telefones = []; // TELEFONES
    data.content.telefones.forEach((telefone) => {
        let telefoneFormated = maskTelefone(telefone.numero);

        telefones.push({
            tipoTelefoneId: telefone.tipoTelefoneId,
            numero: telefoneFormated,
            ddd: telefone.ddd,
            id: telefone.id,
        });
    });

    contatosActions.setTelefones(telefones);
    ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    let contas = []
    setaContasCorrente(data, contas);

    contatosActions.setEmails(data.content.emails); // EMAILS
    documentosActions.setDocumentos(data.content.documentos); // DOCUMENTOS
    contaActions.contaCorrente(contas); // CONTAS CORRENTE

    personaActions.copy({ ...data.content }); // CÓPIA DE PERSONAS PARA FAZER O UPDATE
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function create(
    informacoes,
    enderecos,
    contatos,
    documentos,
    papeis,
    search,
    contasCorrente
) {
    // REMOÇÃO DAS MÁSCARAS DE CPF E CNPJ
    let cpfNoMask =
        informacoes.informacao.cpf !== "" || undefined
            ? removeMaskCPF(informacoes.informacao.cpf)
            : "";
    let cnpjNoMask =
        informacoes.informacao.cnpj !== "" || undefined
            ? removeMaskCNPJ(informacoes.informacao.cnpj)
            : "";

    if (!informacoes.isJuridica) {
    } else if (!informacoes.isJuridica === "PJ") {
    }

    let ie =
        informacoes.informacao.inscricaoEstadual !== undefined
            ? informacoes.informacao.inscricaoEstadual
            : "";
    let im =
        informacoes.informacao.inscricaoMunicipal !== undefined
            ? informacoes.informacao.inscricaoMunicipal
            : "";
    let rendaIndividual =
        papeis.clienteCPF.valorRendaIndividual !== undefined
            ? removeMaskMoeda(papeis.clienteCPF.valorRendaIndividual)
            : "";
    let valorFaturamento =
        papeis.clienteCNPJ.valorFaturamento !== undefined
            ? removeMaskMoeda(papeis.clienteCNPJ.valorFaturamento)
            : "";

    const body = {
        flagJuridica: !informacoes.isJuridica ? false : true,
        nome: !informacoes.isJuridica
            ? informacoes.informacao.nome
            : informacoes.informacao.razaoSocial,
        nomeFantasia: informacoes.isJuridica ? informacoes.informacao.nomeFantasia : null,
        isento: informacoes.isJuridica ? informacoes.informacao.isento : null,
        nomeMae: !informacoes.isJuridica ? informacoes.informacao.nomeMae : "",
        nomePai: !informacoes.isJuridica ? informacoes.informacao.nomePai : "",
        cpfCnpj: !informacoes.isJuridica ? cpfNoMask : cnpjNoMask,
        dataNascimento: !informacoes.isJuridica
            ? informacoes.informacao.dataNascimento
            : informacoes.informacao.dataCriacao,
        enderecos: enderecos[0].tipoEnderecoId !== "0" ? enderecos : [],
        emails: contatos.emails[0].tipoEmailId !== "0" ? contatos.emails : [],
        telefones:
            contatos.telefones[0].tipoTelefoneId !== "0" ? contatos.telefones : [],
        documentos: documentos[0].tipoDocumentoId !== "0" ? documentos : [],
        funcionario: papeis.cliente_cpf.isFuncionarioActive
            ? papeis.funcionario
            : null,
        usuariosBanco: papeis.cliente_cpf.isUserInstituicaoActive
            ? papeis.instituicoesFinanceirasSelected
            : [],
        cliente: papeis.cliente_cpf.isClienteCpfActive
            ? {
                id: papeis.clienteCPF.id,
                valorRendaIndividual: rendaIndividual,
                profissao: papeis.clienteCPF.profissao,
                matriculas: papeis.matriculasSelected,
                convenios: null,
            }
            : papeis.cliente_cnpj.isClienteCnpjActive
                ? {
                    regimeTributarioId: papeis.clienteCNPJ.regimeTributarioId,
                    valorFaturamento: valorFaturamento,
                    id: papeis.clienteCNPJ.id,
                }
                : null,
        loja: papeis.cliente_cnpj.isMatrizActive
            ? {
                tipoLojaId: papeis.tipoLoja.tipoLojaId,
                canalId: papeis.tipoLoja.canalId,
                centrosCusto: papeis.centrosDeCustoSelected,
                bancos: papeis.lojasBancosSelected,
                pessoas: papeis.personasSelected,
            }
            : null,
        instituicaoFinanceira: papeis.cliente_cnpj.isInstituicaoActive
            ? true
            : false,
        ie: ie,
        im: im,
        socios:
            !informacoes.isJuridica !== "PF" &&
                informacoes.socios[0].pessoaSocioId !== "0"
                ? informacoes.socios
                : [],
        rg:
            informacoes.informacao.orgaoRgId !== "0"
                ? {
                    numero: informacoes.informacao.rg,
                    orgaoEmissorId: informacoes.informacao.orgaoRgId,
                    estadoId: informacoes.informacao.ufRgId,
                }
                : null,
        contasCorrente:
            contasCorrente.contasCorrenteSelected[0].instituicaoFinanceiraId !== "0"
                ? contasCorrente.contasCorrenteSelected
                : [],
    };

    setLoading(true)
    const data = await api.post("pessoa", body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    informacoesActions.cleanRegister();
    enderecosActions.cleanRegister();
    contatosActions.cleanRegister();
    papeisActions.cleanRegister();
    documentosActions.cleanRegister();
    contaActions.cleanRegister();
    personaActions.setTab("1");
    get(1, 10, search);
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

export async function update(
    informacoes,
    enderecos,
    contatos,
    documentos,
    contasCorrente,
    papeis,
    copy,
    search
) {
    let cpfNoMask =
        informacoes.informacao.cpf !== undefined
            ? removeMaskCPF(informacoes.informacao.cpf)
            : "";
    let cnpjNoMask =
        informacoes.informacao.cnpj !== undefined
            ? removeMaskCNPJ(informacoes.informacao.cnpj)
            : "";

    // variáveis para fazer a verificação se algo foi alterado

    if (!!informacoes.isJuridica) {
        let dateCopy = copy.dataNascimento.substr(0, 10);

        const informacaoCopy = {
            nome: copy.nome,
            cpf: copy.cpfCnpj,
            rg: copy.rg.numero,
            orgaoRgId: copy.rg.orgaoEmissorId,
            ufRgId: copy.rg.estadoId,
            dataNascimento: dateCopy,
            nomeMae: copy.nomeMae,
            nomePai: copy.nomePai,
        };

        if (
            JSON.stringify(informacoes.informacao) !== JSON.stringify(informacaoCopy)
        ) {
            if (informacoes.informacao.nome?.trim() === "") {
                notifyWarn("Aba Informações: Nome não pode estar em branco");
                informacoesActions.setInvalid("nome");
                return;
            }

            if (cpfNoMask.trim() === "" || !ValidateCPF(cpfNoMask)) {
                notifyWarn("Aba Informações: CPF inválido");
                informacoesActions.setInvalid("cpf");
                return;
            }

            let date = new Date().toISOString().substr(0, 10);

            if (
                informacoes.informacao.dataNascimento === "" ||
                informacoes.informacao.dataNascimento > date
            ) {
                notifyWarn("Aba Informações: Data nascimento inválida");
                informacoesActions.setInvalid("dataNascimento");
                return;
            }
        }
    } else if (!informacoes.isJuridica) {
        if (cnpjNoMask.trim() === "" || !ValidateCNPJ(cnpjNoMask)) {
            notifyWarn("Aba Informações: CNPJ inválido");
            informacoesActions.setInvalid("cnpj");
            return;
        }

        if (informacoes.informacao.razaoSocial?.trim() === "") {
            notifyWarn("Aba Informações: Razão Social não pode estar em branco");
            informacoesActions.setInvalid("razaoSocial");
            return;
        }

        let date = new Date().toISOString().substr(0, 10);

        if (
            informacoes.informacao.dataCriacao === "" ||
            informacoes.informacao.dataCriacao > date
        ) {
            notifyWarn("Aba Informações: Data criação superior à data atual");
            informacoesActions.setInvalid("dataCriacao");
            return;
        }
    }

    let ie =
        informacoes.informacao.inscricaoEstadual !== undefined
            ? informacoes.informacao.inscricaoEstadual
            : "";
    let im =
        informacoes.informacao.inscricaoMunicipal !== undefined
            ? informacoes.informacao.inscricaoMunicipal
            : "";
    let rendaIndividual =
        papeis.clienteCPF.valorRendaIndividual !== undefined
            ? removeMaskMoeda(papeis.clienteCPF.valorRendaIndividual)
            : "";
    let valorFaturamento =
        papeis.clienteCNPJ.valorFaturamento !== undefined
            ? removeMaskMoeda(papeis.clienteCNPJ.valorFaturamento)
            : "";

    const body = {
        flagJuridica: !informacoes.isJuridica ? false : true,
        nome: !informacoes.isJuridica
            ? informacoes.informacao.nome
            : informacoes.informacao.razaoSocial,
        nomeFantasia: informacoes.isJuridica ? informacoes.informacao.nomeFantasia : null,
        isento: informacoes.isJuridica ? informacoes.informacao.isento : null,
        nomeMae: !informacoes.isJuridica ? informacoes.informacao.nomeMae : "",
        nomePai: !informacoes.isJuridica ? informacoes.informacao.nomePai : "",
        cpfCnpj: !informacoes.isJuridica ? cpfNoMask : cnpjNoMask,
        dataNascimento: !informacoes.isJuridica
            ? informacoes.informacao.dataNascimento
            : informacoes.informacao.dataCriacao,
        enderecos: enderecos[0].tipoEnderecoId !== "0" ? enderecos : [],
        emails: contatos.emails[0].tipoEmailId !== "0" ? contatos.emails : [],
        telefones:
            contatos.telefones[0].tipoTelefoneId !== "0" ? contatos.telefones : [],
        documentos: documentos[0].tipoDocumentoId !== "0" ? documentos : [],
        funcionario: papeis.cliente_cpf.isFuncionarioActive
            ? papeis.funcionario
            : null,
        usuariosBanco: papeis.cliente_cpf.isUserInstituicaoActive
            ? papeis.instituicoesFinanceirasSelected
            : [],
        cliente: papeis.cliente_cpf.isClienteCpfActive
            ? {
                id: papeis.clienteCPF.id,
                valorRendaIndividual: rendaIndividual,
                profissao: papeis.clienteCPF.profissao,
                matriculas: papeis.matriculasSelected,
                convenios: null,
            }
            : papeis.cliente_cnpj.isClienteCnpjActive
                ? {
                    regimeTributarioId: papeis.clienteCNPJ.regimeTributarioId,
                    valorFaturamento: valorFaturamento,
                    id: papeis.clienteCNPJ.id,
                }
                : null,
        loja: papeis.cliente_cnpj.isMatrizActive
            ? {
                tipoLojaId: papeis.tipoLoja.tipoLojaId,
                canalId: papeis.tipoLoja.canalId,
                centrosCusto: papeis.centrosDeCustoSelected,
                bancos: papeis.lojasBancosSelected,
                pessoas: papeis.personasSelected,
            }
            : null,
        instituicaoFinanceira: papeis.cliente_cnpj.isInstituicaoActive
            ? true
            : false,
        ie: ie,
        im: im,
        socios:
            informacoes.socios[0].pessoaSocioId !== "0" ? informacoes.socios : [],
        rg:
            informacoes.informacao.orgaoRgId !== "0"
                ? {
                    numero: informacoes.informacao.rg,
                    orgaoEmissorId: informacoes.informacao.orgaoRgId,
                    estadoId: informacoes.informacao.ufRgId,
                }
                : null,
        contasCorrente:
            contasCorrente.contasCorrenteSelected[0].instituicaoFinanceiraId !== "0"
                ? contasCorrente.contasCorrenteSelected
                : [],
        id: copy.id,
    };

    setLoading(true)
    const data = await api.put(`Pessoa/${copy.id}`, body);
    setLoading(false)

    if (!data.success) {
        return;
    }

    notifySuccess("Persona Atualizada");
    informacoesActions.cleanRegister();
    enderecosActions.cleanRegister();
    contatosActions.cleanRegister();
    papeisActions.cleanRegister();
    documentosActions.cleanRegister();
    personaActions.setTab("1");
    personaActions.setActiveTab("Search");
    get(1, 10, search);
}

export async function remove(id, page, rows, search) {

    setLoading(true)
    const data = await api.delete(`Pessoa/${id}`);
    setLoading(false)

    if (!data.success) {
        return;
    }
    notifySuccess("Persona Excluída");
    get(page, rows, search);
}

export async function salveInformation(informacoes) {
    let body;

    let cpfNoMask;
    let cnpjNoMask;
    if (!informacoes.isJuridica) {
        cpfNoMask =
            informacoes.informacao.cpf !== "" || undefined
                ? removeMaskCPF(informacoes.informacao.cpf)
                : "";
    } else {
        cnpjNoMask =
            informacoes.informacao.cnpj !== "" || undefined
                ? removeMaskCNPJ(informacoes.informacao.cnpj)
                : "";
    }

    if (!informacoes.isJuridica) {
        if (cpfNoMask.trim() === "" || !ValidateCPF(cpfNoMask)) {
            notifyWarn("Aba Informações: CPF inválido");
            informacoesActions.setInvalid("cpf");
            return;
        }

        if (informacoes.informacao.nome?.trim() === "") {
            notifyWarn("Aba Informações: Nome não pode estar em branco");
            informacoesActions.setInvalid("nome");
            return;
        }

        let date = new Date().toISOString().substr(0, 10);
        if (
            informacoes.informacao.dataNascimento === "" ||
            informacoes.informacao.dataNascimento > date
        ) {
            notifyWarn("Aba Informações: Data nascimento inválida");
            informacoesActions.setInvalid("dataNascimento");
            return;
        }

        if (informacoes.informacao.nomeMae?.trim() === "") {
            notifyWarn("Aba Informações: Nome da mãe não pode estar em branco");
            informacoesActions.setInvalid("nomeMae");
            return;
        }

        body = {
            flagJuridica: informacoes.isJuridica,
            nome: informacoes.informacao.nome,
            cpfCnpj: cpfNoMask,
            rg: informacoes.informacao.rg,
            dataNascimento: informacoes.informacao.dataNascimento,
            rgOrgaoEmissorId: informacoes.informacao.orgaoRgId === '0' ? null : informacoes.informacao.orgaoRgId,
            estadoOrgaoEmissorId: informacoes.informacao.ufRgId === '0' ? null : informacoes.informacao.ufRgId,
            nomeMae: informacoes.informacao.nomeMae,
            nomePai: informacoes.informacao.nomePai,
        };
    } else if (informacoes.isJuridica) {
        if (cnpjNoMask.trim() === "" || !ValidateCNPJ(cnpjNoMask)) {
            notifyWarn("Aba Informações: CNPJ inválido");
            informacoesActions.setInvalid("cnpj");
            return;
        }

        if (informacoes.informacao.razaoSocial?.trim() === "") {
            notifyWarn("Aba Informações: Razão Social não pode estar em branco");
            informacoesActions.setInvalid("razaoSocial");
            return;
        }

        if (informacoes.informacao.nomeFantasia?.trim() === "") {
            notifyWarn("Aba Informações: Nome Fantasia não pode estar em branco");
            informacoesActions.setInvalid("nomeFantasia");
            return;
        }

        let date = new Date().toISOString().substr(0, 10);

        if (
            informacoes.informacao.dataCriacao === "" ||
            informacoes.informacao.dataCriacao > date
        ) {
            notifyWarn("Aba Informações: Data criação superior à data atual");
            informacoesActions.setInvalid("dataCriacao");
            return;
        }

        if (informacoes.informacao.inscricaoMunicipal === "") {
            return notifyWarn("Aba Informações: Inscrição Municipal não pode estar em branco");
        }

        let sociosNaoVinculados = []

        informacoes.socios.forEach((socio) => {
            if (!socio.id) {
                sociosNaoVinculados.push(socio)
            }
        })

        body = {
            flagJuridica: informacoes.isJuridica,
            nome: informacoes.informacao.razaoSocial?.trim(),
            nomeFantasia: informacoes.informacao.nomeFantasia,
            isento: informacoes.informacao.isento,
            cpfCnpj: cnpjNoMask.trim(),
            dataNascimento: informacoes.informacao.dataCriacao,
            ie:
                informacoes.informacao.inscricaoEstadual !== undefined
                    ? informacoes.informacao.inscricaoEstadual
                    : "",
            im:
                informacoes.informacao.inscricaoMunicipal !== undefined
                    ? informacoes.informacao.inscricaoMunicipal
                    : "",
            socios: sociosNaoVinculados,
        };
    }
    let data;
    if (informacoes.informacao.id === null) {
        setLoading(true);
        data = await api.post(`Pessoa`, body);
        setLoading(false);
        if (!data.success) {
            return;
        }

        informacoesActions.item({ ...informacoes.informacao, id: data.content.id });
        notifySuccess("Persona Cadastrada");
    } else {
        setLoading(true);
        data = await api.put(`Pessoa/${informacoes.informacao.id}`, body);
        setLoading(false);
        if (!data.success) {
            return;
        }
        notifySuccess("Pessoa atualizada com sucesso")
    }
}

export async function salveCliente(id, isJuridica, cliente, matricula) {
    let body;
    if (isJuridica) {
        body = {
            id: cliente.id,
            valorFaturamento: removeMaskMoeda(cliente.valorFaturamento === ""
                ? '0,00'
                : cliente.valorFaturamento),
            regimeTributarioId: cliente.regimeTributarioId === "0"
                ? "00000000-0000-0000-0000-000000000000"
                : cliente.regimeTributarioId,
        };
    } else {
        let matriculas = [];
        if (matricula.numero !== '') {
            matricula.valorRendaIndividual = removeMaskMoeda(matricula.valorRendaIndividual) !== ''
                ? removeMaskMoeda(matricula.valorRendaIndividual)
                : '0.00';
            matriculas.push(matricula);
        }

        body = {
            id: cliente.id,
            juridico: isJuridica,
            matriculas: matriculas,
            profissao: cliente.profissao,
        };
    }
    setLoading(true);
    const data = await api.put(`Pessoa/${id}/clientes`, body);
    setLoading(false);

    if (!data.success) {
        throw data
    }

    if (!isJuridica) {
        papeisActions.clienteCpf({ ...cliente, id: data.content.cliente.id });
        papeisActions.setMatriculasSelected(data.content.cliente.matriculas);
        papeisActions.cleanMatricula();
    } else {
        papeisActions.clienteCnpj({ ...cliente, id: data.content.cliente.id });
    }

    notifySuccess("Cliente salvo");
}

export async function salveUsuariosBanco(id, usuarioBanco) {

    if (usuarioBanco.usuarioInstituicao === "") {
        return notifyWarn("Usuário não pode estar em branco")
    }

    if (usuarioBanco.bancoId === "0") {
        return notifyWarn("Selecione uma instituição financeira")
    }

    if (usuarioBanco.dataVigenciaInicial === "") {
        return notifyWarn("Informe a data inicial da vigência")
    }

    if (usuarioBanco.dataVigenciaFinal && usuarioBanco.dataVigenciaInicial > usuarioBanco.dataVigenciaFinal) {
        return notifyWarn("Data inicial não pode ser maior que data final")
    }

    let dataFormated = {
        id: usuarioBanco.id,
        bancoId: usuarioBanco.bancoId,
        dataVigenciaInicial: usuarioBanco.dataVigenciaInicial !== "" ? usuarioBanco.dataVigenciaInicial : null,
        dataVigenciaFinal: usuarioBanco.dataVigenciaFinal !== "" ? usuarioBanco.dataVigenciaFinal : null,
        usuarioInstituicao: usuarioBanco.usuarioInstituicao,
        statusUsuario: usuarioBanco.statusUsuario
    }

    setLoading(true);
    const data = await api.put(`Pessoa/${id}/usuarios-banco`, dataFormated);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    data.content.usuariosBanco.forEach(item => {
        item.loja = item.loja.nomeFantasia ?? item.loja.nome
    })
    papeisActions.userInstituicao(data.content.usuariosBanco);
    papeisActions.cleanInstituicao();

    notifySuccess("Usuário da instituição salvo");
    return true
}

export async function salveContato(id, contato, isTelefone) {
    let contatos = [];
    contatos.push(contato);
    let body = {
        telefones: null,
        emails: null,
    };

    if (isTelefone) {
        body = { ...body, telefones: contatos };
    } else {
        body = { ...body, emails: contatos };
    }

    setLoading(true);
    const data = await api.put(`Pessoa/${id}/contatos`, body);
    setLoading(false);

    if (!data.success) {
        return;
    }

    if (isTelefone) {
        contatosActions.setTelefones(data.content.telefones);
        contatosActions.cleanTelefone();
        notifySuccess("Telefone salvo");
    } else {
        contatosActions.setEmails(data.content.emails);
        contatosActions.cleanEmail();
        notifySuccess("E-mail salvo");
    }
}

export async function deleteTelefone(id) {
    setLoading(true);
    const data = await api.delete(`Pessoa/${id}/telefones`);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    notifySuccess("Telefone excluído");
    return true
}

export async function getFuncionarioLoja(idPessoa) {
    const data = await api.get(`lojas/funcionario/${idPessoa}`);

    if (!data.success) {
        return;
    }

    papeisActions.setFuncionarioLoja(data.content);
}

export async function getLojasBanco(idBanco, isSelect) {
    setLoading(true);
    const data = await api.get(`lojas/bancos/${idBanco}`);
    setLoading(false);

    if (!isSelect) {
        if (!data.success) {
            return;
        }
    }

    papeisActions.setLojas(data.content);
}

export async function salveFuncionario(id, funcionario) {

    let body = {}

    if (funcionario.tipoFuncionarioId === '0' || funcionario.tipoFuncionario === '0') {
        notifyWarn('O campo Tipo funcionário não pode ficar em branco')
        return false
    }

    if (funcionario.possuiRamal === false) {
        body = {
            possuiRamal: funcionario.possuiRamal,
            tipoFuncionarioId: funcionario.tipoFuncionarioId,
            centroCustoId: funcionario.centroCustoId,
            id: funcionario.id,
            codigoEmpresa: funcionario.codigoEmpresa,
            codigoCentroCusto: funcionario.codigoCentroCusto,
            razaoSocialEmpresa: funcionario.razaoSocialEmpresa,
            codigoFuncionarioErp: funcionario.codigoFuncionarioERP,
            dataDemissao: funcionario.dataDemissao === '' ? null : funcionario.dataDemissao,
            dataAdmissao: funcionario.dataAdmissao,
            tipoFuncionario: funcionario.tipoFuncionario,
            lojaId: funcionario.lojaId !== '0' ? funcionario.lojaId : null
        }
    } else {
        if (funcionario.ramal === '') {
            notifyWarn('Informe o ramal')
        }
        body = {
            ramal: funcionario.ramal,
            possuiRamal: funcionario.possuiRamal,
            tipoFuncionarioId: funcionario.tipoFuncionarioId,
            centroCustoId: funcionario.centroCustoId,
            id: funcionario.id,
            codigoEmpresa: funcionario.codigoEmpresa,
            codigoCentroCusto: funcionario.codigoCentroCusto,
            razaoSocialEmpresa: funcionario.razaoSocialEmpresa,
            codigoFuncionarioErp: funcionario.codigoFuncionarioERP,
            dataDemissao: funcionario.dataDemissao === '' ? null : funcionario.dataDemissao,
            dataAdmissao: funcionario.dataAdmissao,
            tipoFuncionario: funcionario.tipoFuncionario,
            lojaId: funcionario.lojaId !== '0' ? funcionario.lojaId : null
        }
    }

    setLoading(true);
    const data = await api.put(`/Pessoa/${id}/funcionarios`, body);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    papeisActions.funcionario({ ...funcionario, 'id': data.content.funcionario.id })
    documentosActions.setDocumentos(data.content.documentos);
    notifySuccess("Funcionario salvo");
    return true
}

export async function salveDocumentacao(id, documentacao) {
    let body = [];

    body.push(documentacao);

    setLoading(true);
    const data = await api.put(`Pessoa/${id}/documentos`, body);
    setLoading(false);

    if (!data.success) {
        return;
    }
    documentosActions.setDocumentos(data.content.documentos);
    notifySuccess("Documentação salva");
}

export async function removeDocumentacao(id) {

    setLoading(true);
    const data = await api.delete(`Pessoa/${id}/documentos`);
    setLoading(false);

    if (!data.success) {
        return false;
    }
    return true
}

export async function getDocumentoBASE64(documentoId) {
    setLoading(true);
    const data = await api.get(`Documento/${documentoId}`);
    setLoading(false);

    if (!data.success) {
        return;
    }
    return data.content
}

export async function salveCentroCusto(id, idLoja, centroCustos) {
    if (centroCustos.id === "0") {
        return notifyWarn(
            "Aba Relacionamento: Selecione um Centro de Custo para adicionar um novo"
        );
    }

    let centrosCusto = [];
    centrosCusto.push(centroCustos);

    let body = {
        id: idLoja,
        centrosCusto: centrosCusto,
    };

    setLoading(true);
    const data = await api.put(`Pessoa/${id}/lojas/centro-custo`, body);
    setLoading(false);

    if (!data.success) {
        return;
    }
    papeisActions.setCentrosSelected(data.content.loja.centrosCusto);
}

export async function salveLoja(id, Loja) {
    if (Loja.tipoLojaId === "0") {
        return notifyWarn("Por favor informe um Tipo de Loja")
    }
    if ((!Loja.isMatriz && Loja.matrizId === "0") || (!Loja.isMatriz && !Loja.matrizId)) {
        return notifyWarn("Por favor informe uma loja matriz")
    }

    if (Loja.canalId === "0") {
        return notifyWarn("Por favor informe um Canal")
    }

    if (Loja.dataFimVigencia === "") {
        Loja.dataFimVigencia = null;
    }

    if (Loja.dataFimVigencia !== "") {
        if (Loja.dataInicioVigencia > Loja.dataFimVigencia) {
            return notifyWarn("Data fim vigência não pode ser menor do que a data de início.")
        }

    }

    if (Loja.dataInicioVigencia === "") {
        Loja.dataInicioVigencia = null;
    }

    if (Loja.ir === "") {
        Loja.ir = 0;
    } else {
        Loja.ir = removeMaskMoeda(Loja.ir)
    }

    const { canalId, dataFimVigencia, dataInicioVigencia, ir, tipoLojaId, isMatriz, matrizId, empresa, exibeSimulador } = Loja

    setLoading(true);
    const data = await api.put(`Pessoa/${id}/lojas`, {
        canalId,
        id: Loja.id,
        dataFimVigencia,
        dataInicioVigencia,
        tipoLojaId,
        exibeSimulador,
        matriz: isMatriz,
        lojaMatrizId: matrizId === '0' ? null : matrizId,
        ir,
        empresa,
        codigoEmpresaW3ERP: Loja.cdEmpresaW3ERP
    });
    setLoading(false);

    if (!data.success) {
        return data.success;
    }

    papeisActions.tipoLoja({ ...Loja, id: data.content.loja.id, ir: maskMoeda(Loja.ir) });
    notifySuccess("Loja salva");
    return data.content.loja.id;
}

export async function salveFornecedor(idPessoa, fornecedor) {
    const body = { codigoFornecedor: fornecedor.codigoFornecedor, pessoaId: idPessoa };
    let data;
    if (fornecedor.id === "") {
        setLoading(true);
        data = await api.post(`/Fornecedor`, body);
        setLoading(false);
        papeisActions.setFonecedor({ ...fornecedor, id: data.content.id });
    } else {
        setLoading(true);
        data = await api.put(`/Fornecedor/${fornecedor.id}`, body);
        setLoading(false);
    }
    if (!data.success) {
        return data.success;
    }

    notifySuccess("Fornecedor salvo");
    return data.success;
}

export async function salveLojaBanco(id, lojaId, lojaBanco) {

    if (lojaBanco.bancoId === "0") {
        return notifyWarn("Selecione uma instituição")
    }

    if (lojaBanco.tipoParceriaId === "0") {
        return notifyWarn("Selecione um tipo de parceria")
    }

    if (lojaBanco.codigoLojaBanco === "" && lojaBanco.tipoParceria !== 'AGENTE') {
        return notifyWarn("Preencha o campo de código de loja da instituição")
    }

    if (lojaBanco.dataVigenciaInicial === "") {
        return notifyWarn("Indique o início da vigência")
    }

    if (lojaBanco.dataVigenciaFinal !== "" && lojaBanco.dataVigenciaInicial > lojaBanco.dataVigenciaFinal) {
        return notifyWarn("Data de início não pode ser maior que data data fim")
    }

    let body = {
        id: lojaBanco.id !== '0' ? lojaBanco.id : null,
        lojaId: lojaId,
        bancoId: lojaBanco.bancoId,
        tipoParceriaId: lojaBanco.tipoParceriaId,
        possuiComissao: lojaBanco.possuiComissao,
        codigoLojaBanco: lojaBanco.codigoLojaBanco !== '0' ? lojaBanco.codigoLojaBanco : null,
        dataVigenciaInicial: lojaBanco.dataVigenciaInicial,
        dataVigenciaFinal: lojaBanco.dataVigenciaFinal !== '' ? lojaBanco.dataVigenciaFinal : null,
        lojaMasterId: lojaBanco.lojaMasterId !== '0' ? lojaBanco.lojaMasterId : null
    };
    setLoading(true);
    const data = await api.put(`Pessoa/${id}/lojas/bancos`, body);
    setLoading(false);

    if (!data.success) {
        papeisActions.cleanInstituicao();
        papeisActions.cleanLojaBanco();
        return data.success;
    }

    let dataFormated = []

    data.content.loja.bancos.forEach((banco) => {
        dataFormated.push({
            id: banco.id,
            bancoId: banco.bancoId,
            tipoParceriaId: banco.tipoParceriaId,
            possuiComissao: banco.possuiComissao,
            codigoLojaBanco: banco.codigoLojaBanco,
            lojaMasterId: banco.lojaMasterId,
            dataVigenciaInicial: banco.dataVigenciaInicial ? banco.dataVigenciaInicial.substr(0, 10) : "",
            dataVigenciaFinal: banco.dataVigenciafinal ? banco.dataVigenciafinal.substr(0, 10) : "",
            divisaoComissao: banco.divisaoComissao,
        })
    })

    papeisActions.setLojasBanco(dataFormated);
    notifySuccess("Instituição financeira salva");
    papeisActions.cleanInstituicao();
    papeisActions.cleanLojaBanco();
    return data.success;
}

export async function deleteLojaBanco(lojaId) {
    setLoading(true);
    const data = await api.delete(`Pessoa/${lojaId}/lojas/bancos`);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    return true;
}

export async function salvePersonas(lojaId, persona) {
    if (
        persona.hierarquiaId === "0" ||
        persona.pessoaId === "0" ||
        persona.centroCustoId === '0'
    ) {
        notifyWarn(
            "Aba Relacionamento: Preencha os campos para adicionar uma nova Personas."
        );
        return;
    }

    var currentDate = new Date().toISOString()

    let body = {
        id: persona.id,
        lojaId: lojaId,
        pessoaId: persona.pessoaId,
        hierarquiaId: persona.hierarquiaId,
        dataVigenciaInicial: persona.dataVigenciaInicial || maskDate(currentDate, 'YYYY-MM-DD'),
        dataVigenciaFinal: persona.dataVigenciaFinal && persona.dataVigenciaFinal !== "" ? persona.dataVigenciaFinal : null,
        headCount: persona.headCount,
        usuarioBancoId: persona.usuarioBancoId !== "0" ? persona.usuarioBancoId : null,
        centroCustoId: persona.centroCustoId !== '0' ? persona.centroCustoId : null,
    };
    setLoading(true);
    const data = await api.put(`Pessoa/loja-pessoa`, body);
    setLoading(false);

    if (!data.success) {
        return false;
    }

    setarUsuariosBancoAposAtualizacaoOuAdicao(data);

    notifySuccess("Persona salva");
    return true;
}

function setarUsuariosBancoAposAtualizacaoOuAdicao(data) {
    let personas = []

    data.content.loja.pessoas.forEach((pessoa) => {
        let dataInicial =
            pessoa.dataVigenciaInicial !== null &&
            maskDate(pessoa.dataVigenciaInicial, "YYYY-MM-DD");
        let dataFinal =
            pessoa.dataVigenciafinal !== null &&
            maskDate(pessoa.dataVigenciafinal, "YYYY-MM-DD");

        personas.push({
            dataVigenciaFinal: dataFinal,
            dataVigenciaInicial: dataInicial,
            hierarquiaId: pessoa.hierarquiaId,
            id: pessoa.id,
            centroCustoId: pessoa?.centroCusto?.id,
            centroCusto: pessoa?.centroCusto?.descricao,
            pessoaId: pessoa.pessoaId,
            headCount: pessoa.headCount,
            usuarioBanco: pessoa.usuarioBanco,
        });
    })

    personas = personas.reduce((obj, pessoa) => {
        if (!obj[`${pessoa.pessoaId},${pessoa.hierarquiaId}`]) obj[`${pessoa.pessoaId},${pessoa.hierarquiaId}`] = [];
        obj[`${pessoa.pessoaId},${pessoa.hierarquiaId}`].push(pessoa);
        return obj;
    }, {});

    const newPessoas = Object.keys(personas).map(pessoa => {
        const [pessoaId] = pessoa.split(',')
        return {
            header: {
                pessoaId,
                hierarquiaId: personas[pessoa][0].hierarquiaId,
                headCount: personas[pessoa][0].headCount
            },
            body: personas[pessoa]
        };
    });

    papeisActions.setPersonas(newPessoas)
    papeisActions.setUsuariosBanco([]);
}

export async function deletePersonas(idPersona) {
    setLoading(true);
    const data = await api.delete(`Pessoa/loja-pessoa/${idPersona}`);
    setLoading(false);

    if (!data.success) {
        return data.success;
    }

    return data.success;
}

export async function salveContaCorrente(id, conta) {
    let body = [];

    let retorno = validaContaCorrente(conta, body);
    if (retorno === false) {
        return false;
    }

    setLoading(true);
    const data = await api.put(`Pessoa/${id}/contas-corrente`, body);
    setLoading(false);

    if (!data.success) {
        cleanDados();
        return data.success;
    }

    let contas = []
    setaContasCorrente(data, contas);

    contaActions.contaCorrente(contas);
    notifySuccess("Conta corrente adicionada com sucesso");
    cleanDados();
    return { valid: true }
}

export async function updateContaCorrente(id, conta) {
    let body = [];

    let retorno = validaContaCorrente(conta, body);
    if (retorno === false) {
        return false;
    }

    setLoading(true);
    const data = await api.put(`Pessoa/${id}/conta-corrente`, body);
    setLoading(false);

    if (!data.success) {
        cleanDados();
        return data.success;
    }

    let contas = []
    setaContasCorrente(data, contas);

    contaActions.contaCorrente(contas);
    notifySuccess("Conta Corrente atualizada com sucesso!");
    cleanDados();
    return { valid: true };
}

export async function deleteMatricula(id) {
    setLoading(true);
    const data = await api.delete(`Pessoa/${id}/matriculas`);
    setLoading(false);

    if (!data.success) {
        return data.success;
    }

    notifySuccess("Matricula removida")
    return data.success;
}

function cleanDados() {
    contaActions.clean();
    contaActions.setIsEditando(false);
    contaActions.setIsExcluindo(false);
}

export async function getUsuarioBanco(id) {
    setLoading(true);
    const data = await api.get(`UsuarioBanco/${id}`);
    setLoading(false)

    papeisActions.setInstituicao('bancoId', data.content.bancoId);
    await getLojasBanco(data.content.bancoId, true);
    await controllerPapeis.getCanais(true);
    papeisActions.setInstituicao('dataVigenciaInicial', maskDate(data.content.dataVigenciaInicial, "YYYY-MM-DD"))
    papeisActions.setInstituicao('dataVigenciaFinal', maskDate(data.content.dataVigenciaFinal, "YYYY-MM-DD"))
    papeisActions.setInstituicao('id', data.content.id)
    papeisActions.setInstituicao('usuarioInstituicao', data.content.usuarioInstituicao)
    papeisActions.setInstituicao('statusUsuario', data.content.statusUsuario)

    if (data.content.loja.id !== guidNull) {
        papeisActions.setInstituicao('lojaId', data.content.loja.id);
        papeisActions.setInstituicao('possuiRelacionamento', true);
        await controllerPapeis.getCentrosCustoParaSelect(data.content.loja.id);
    } else {
        papeisActions.setInstituicao('lojaId', '0');
        papeisActions.setInstituicao('possuiRelacionamento', false);
    }

    if (data.content.loja.centroCustoId !== guidNull && data.content.loja.vinculoCentroCustoId !== guidNull) {
        papeisActions.setInstituicao('centroCustoId', data.content.loja.centroCustoId);
        papeisActions.setInstituicao('vinculoCentroCustoId', data.content.loja.vinculoCentroCustoId);
    } else {
        papeisActions.setInstituicao('centroCustoId', '0');
        papeisActions.setInstituicao('vinculoCentroCustoId', '0');
    }

    if (data.content.loja.canalId !== guidNull) {
        papeisActions.setInstituicao('canalId', data.content.loja.canalId);
        await controllerPapeis.getHierarquiaParaSelect(data.content.loja.canalId);
    } else {
        papeisActions.setInstituicao('canalId', '0');
    }

    if (data.content.loja.hierarquiaId !== guidNull) {
        papeisActions.setInstituicao('nivelHierarquicoId', data.content.loja.hierarquiaId);
    } else {
        papeisActions.setInstituicao('nivelHierarquicoId', '0');
    }

    papeisActions.setInstituicao('headCount', data.content.loja.headCount);
}

export async function deleteUsuarioBanco(id) {
    setLoading(true);
    const data = await api.delete(`Pessoa/${id}/usuarios-banco`);
    setLoading(false);

    if (!data.success) {
        return data.success;
    }

    return data.success;
}


export async function isIstituicao(idPessoa) {
    setLoading(true);
    const data = await api.put(`Pessoa/${idPessoa}/instituicoes`, {});
    setLoading(false);

    if (!data.success) {
        return data.success;
    }

    notifySuccess("Instituição financeira vinculada");

    return data.success;
}

export async function verificaHierarquia(hierarquiaParaAlterar) {
    if (hierarquiaParaAlterar.hierarquiaNovaId === "0") {
        return notifyWarn('Favor selecionar uma hierarquia!');
    }

    setLoading(true);
    const data = await api.put(`Pessoa/loja-pessoa/verifica-hierarquia`, hierarquiaParaAlterar);
    setLoading(false)

    if (!data.success) {
        return data.success;
    }

    return data.content;
}

export async function updateHierarquia(hierarquiaParaAlterar) {
    if (hierarquiaParaAlterar.hierarquiaNovaId === "0") {
        return notifyWarn('Favor selecionar uma hierarquia!');
    }

    setLoading(true);
    const data = await api.put(`Pessoa/loja-pessoa/alterar-hierarquia`, hierarquiaParaAlterar);
    setLoading(false)

    if (!data.success) {
        return data.success;
    }

    setarUsuariosBancoAposAtualizacaoOuAdicao(data);
    papeisActions.cleanAlteraHierarquia();
    notifySuccess("Hierarquia alterada com sucesso!");
    return true;
}

function setaContasCorrente(data, contas) {
    data.content.contasCorrente.forEach((e) => {
        e.matriculas.forEach((e) => {
            e.nome = e.numero
        })
        contas.push({
            id: e.id,
            instituicaoFinanceiraId: e.bancoDadosBancarioId,
            nomeAgencia: e.nomeAgencia,
            chavePix: e.chavePix,
            tipoConta: e.tipoConta.id,
            digitoAgencia: e.digitoAgencia,
            digitoConta: e.digitoConta,
            tipoPixId: e.tipoPixId,
            numero: e.numero,
            matricula: '0',
            matriculasContaCorrente: e.matriculas,
            flagPadrao: e.flagPadrao,
            cdContaCorrenteW3ERP: e.codigoContaCorrenteW3ERP
        });
    });
}

function validaContaCorrente(conta, body) {
    if (conta.instituicaoFinanceiraId === '0') {
        notifyWarn('Informe um Banco')
        return false;
    }
    if (conta.tipoConta === '0') {
        notifyWarn('Informe o Tipo da conta')
        return false;
    }
    if (conta.chavePix === '') {
        if (conta.nomeAgencia === '') {
            notifyWarn('Informe a Agencia')
            return false;
        }
        if (conta.numero === '') {
            notifyWarn('Informe a Conta Corrente')
            return false;
        }
    }
    if (conta.chavePix !== '' && conta.tipoPixId === '0') {
        notifyWarn('Informe o tipo de chave')
        return false;
    }

    let newArray = [];

    let matri = conta.matricula === '0' ? '00000000-0000-0000-0000-000000000000' : conta.matricula;
    newArray.push({ id: matri });

    if (conta.tipoPixId === "0") {
        conta.tipoPixId = null
    }

    body.push({
        id: conta.id,
        bancoId: conta.instituicaoFinanceiraId,
        nomeAgencia: conta.nomeAgencia,
        digitoAgencia: conta.digitoAgencia,
        digitoConta: conta.digitoConta,
        numero: conta.numero,
        flagPadrao: conta.flagPadrao,
        chavePix: conta.chavePix,
        tipoPixId: conta.tipoPixId,
        tipoContaId: conta.tipoConta,
        matriculas: newArray,
        codigoContaCorrenteW3ERP: conta.cdContaCorrenteW3ERP
    });
}
