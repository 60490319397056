import React, { useEffect, useMemo, useState } from "react";
import { usePagination, useTable } from "react-table";
import ReactPaginate from "react-paginate";
import { useSelector, useDispatch } from "react-redux";
import {
  Modal,
  ModalBody,
  Col,
  Button,
  Input,
  Label,
  Row,
  Navbar,
  FormGroup,
} from "reactstrap";
import { Select } from "../../../components/Inputs/Input";
import Autocomplete from "react-autocomplete";
import * as actions from "../../../store/modules/conferenciaCr/actions";
import * as controller from "../../../controllers/conferenciaCrController";
import { maskCNPJ } from "../../../util/masks";

export default function ModalLoja({ isOpen, toggle }) {
  const { search, lojasMatrizes, getAllLoja, canalId } = useSelector(
    (state) => state.conferenciaCr
  );

  const columns = useMemo(
    () => [
      {
        Header: "CNPJ",
      },
      {
        Header: "NOME",
      },
    ],
    []
  );
  const data = lojasMatrizes;

  const [nomeFantasia, setNomeFantasia] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedCanalId, setSelectedCanalId] = useState("");

  const { page, gotoPage, state, setPageSize } = useTable(
    {
      columns,
      data,
    },
    usePagination
  );

  const { pageSize } = state;
  const [rows, setRows] = useState(0);
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);

  useEffect(() => {
    setRows(page.length);
  }, [page]);

  const handlePageClick = (page) => {
    const pageSelected = page.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    controller.getLojasCanal(pageSelected + 1, pageSize, {
      nomeFantasia,
      cnpj,
      canalId: selectedCanalId, 
    });
  };
  function handleInitialPage(pageSize) {
    const pageSelected = 0;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    controller.getLojasCanal(pageSelected + 1, pageSize, {
      nomeFantasia,
      cnpj,
      canalId: selectedCanalId, 
    });
  }

  function onChange(value, field) {
    actions.setSearch(field, value);
  }

  const handleSearch = () => {
    controller.getLojasCanal(1, pageSize, {
      nomeFantasia,
      cnpj,
      canalId: selectedCanalId,
    });
  };

  const handleInputChangeNome = (value, setFunction) => {
    setFunction(value);
    const filteredSuggestions = lojasMatrizes.filter((loja) =>
      loja.nomeFantasia.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };

  const handleInputChangeCnpj = (value, setFunction) => {
    setFunction(value);
    const filteredSuggestions = lojasMatrizes.filter((loja) =>
      loja.cnpj.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };
  const handleInputChangeCanal = (value, setFunction) => {
    setFunction(value);
    const filteredSuggestions = lojasMatrizes.filter((loja) =>
      loja.canalId.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  };
  const handleSelectSuggestion = (value, setFunction) => {
    setFunction(value);
    setSuggestions([]);
  };

  return (
    <>
      <Modal
        className="text-center"
        isOpen={isOpen}
        toggle={toggle}
        backdrop={true}
        size="lg"
      >
        <Navbar
          expand="md"
          style={{
            width: "100%",
            background: "var(--color-gradiente-verde-claro)",
            marginBottom: "20px",
          }}
        >
          <h5 style={{ color: "white" }} className="ml-auto mr-auto mt-2">
            Pesquisa de Lojas
          </h5>
        </Navbar>
        <ModalBody>
          <Row>
            <Col lg={4}>
              <FormGroup style={{ fontSize: "16px", textAlign: "left" }}>
                <Label className="mr-2" style={{ fontSize: "16px" }}>
                  CNPJ:
                </Label>
                <Autocomplete
                  placeholder="Digite o CNPJ"
                  getItemValue={(item) => item.cnpj}
                  items={suggestions}
                  value={cnpj}
                  onChange={(e) =>
                    handleInputChangeCnpj(e.target.value, setCnpj)
                  }
                  onSelect={(value) => handleSelectSuggestion(value, setCnpj)}
                  renderItem={(item, isHighlighted) => (
                    <div
                      key={item.id}
                      style={{
                        background: isHighlighted ? "lightgray" : "white",
                        padding: "5px",
                        borderRadius: "5px",
                      }}
                    >
                      {maskCNPJ(item.cnpj)}
                    </div>
                  )}
                  wrapperStyle={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                    fontSize: "16px",
                  }}
                  inputProps={{
                    style: {
                      width: "100%",
                      height: "34px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ced4da",
                      paddingLeft: "10px",
                    },
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={4}>
              <FormGroup style={{ fontSize: "16px", textAlign: "left" }}>
                <Label className="mr-2" style={{ fontSize: "16px" }}>
                  Nome:
                </Label>
                <Autocomplete
                  placeholder="Digite o nome"
                  getItemValue={(item) => item.nomeFantasia}
                  items={suggestions}
                  value={nomeFantasia}
                  onChange={(e) =>
                    handleInputChangeNome(e.target.value, setNomeFantasia)
                  }
                  onSelect={(value) =>
                    handleSelectSuggestion(value, setNomeFantasia)
                  }
                  renderItem={(item, isHighlighted) => (
                    <div
                      key={item.id}
                      style={{
                        background: isHighlighted ? "lightgray" : "white",
                        padding: "5px",
                        borderRadius: "5px",
                        fontSize: "16px",
                      }}
                    >
                      {item.nomeFantasia}
                    </div>
                  )}
                  wrapperStyle={{
                    position: "relative",
                    display: "inline-block",
                    width: "100%",
                  }}
                  inputProps={{
                    style: {
                      width: "100%",
                      height: "34px",
                      fontSize: "16px",
                      borderRadius: "5px",
                      border: "1px solid #ced4da",
                      paddingLeft: "10px",
                    },
                  }}
                />
              </FormGroup>
            </Col>
            <Col lg={4}>
              <FormGroup style={{ fontSize: "16px", textAlign: "left" }}>
                <Label
                  className="mr-2"
                  style={{
                    fontSize: "16px",
                    width: "100px",
                    textAlign: "left",
                  }}
                >
                  Canal:
                </Label>
                <Select
                  first="Selecione"
                  value={{ value: selectedCanalId }}
                  select={{ value: "id", label: "nome" }}
                  options={canalId}
                  onChange={(e) => setSelectedCanalId(e.value)}
                  isSearchable={true}
                  autoComplete="off"
                  style={{
                    height: "40px",
                    fontSize: "16px",
                    borderRadius: "5px",
                    border: "1px solid #ced4da",
                    paddingLeft: "10px",
                  }}
                />
              </FormGroup>
            </Col>
          </Row>
          <fieldset
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              maxHeight: "300px",
              overflowY: "auto",
              padding: "10px",
            }}
          >
            <legend style={{ textAlign: "center", fontSize: "20px" }}>
              Resultados
            </legend>
            <table
              style={{
                width: "100%",
                textAlign: "center",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      width: "50%",
                      textAlign: "left",
                      padding: "10px",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    CNPJ
                  </th>
                  <th
                    style={{
                      width: "50%",
                      textAlign: "left",
                      padding: "10px",
                      borderBottom: "1px solid #ddd",
                    }}
                  >
                    Nome
                  </th>
                </tr>
              </thead>
              <tbody>
                {lojasMatrizes &&
                  lojasMatrizes.map((row, index) => (
                    <tr
                      onDoubleClick={() => {
                        actions.setSearch("loja", row.nomeFantasia);
                        actions.setSearch("lojaId", row.id);
                        toggle();
                      }}
                    >
                      <td style={{ padding: "10px" }}>{maskCNPJ(row.cnpj)}</td>
                      <td style={{ padding: "10px" }}>{row.nomeFantasia}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <br />
          </fieldset>

          {lojasMatrizes && (
            <Row className="pt-4">
              <Col md={3}>
                <span style={{ color: "var(--color-cinza-medio)" }}>
                  Mostrando {rows} de {getAllLoja.quantidadeTotal}
                </span>
              </Col>
              <Col md={8} className="float-right">
                <Row>
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    initialPage={0}
                    pageCount={getAllLoja.totalPaginas}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={"pagination"}
                    subContainerClassName={"pages pagination"}
                    activeClassName={"active"}
                    forcePage={initialPage ? 0 : selectedPage}
                  />
                  <Input
                    type="select"
                    className="pageSize"
                    value={pageSize}
                    onChange={(e) => {
                      setPageSize(Number(e.target.value));
                      handleInitialPage(e.target.value);
                    }}
                  >
                    {[10, 25, 100].map((pageSize) => (
                      <option key={pageSize} value={pageSize}>
                        {pageSize}
                      </option>
                    ))}
                  </Input>
                </Row>
              </Col>
            </Row>
          )}
        </ModalBody>
        <div className="m-3">
          <Button
            onClick={() => {
              actions.setLojas([]);
              setCnpj("");
              setNomeFantasia("");
              setSelectedCanalId(null);
              setSelectedCanalId("");
            }}
            className="mr-4"
            style={{
              width: "150px",
              background: "var(--color-branco)",
              borderColor: "var(--color-laranja)",
              color: "var(--color-laranja)",
              borderRadius: "7px",
            }}
          >
            Limpar
          </Button>
          <Button
            onClick={handleSearch}
            color="danger"
            className="purpleButton"
            style={{ width: "150px" }}
          >
            Pesquisar
          </Button>
        </div>
      </Modal>
    </>
  );
}
