import { STORE_ID, TYPES } from "./types";
import store from "../../../index";

function dispatch(data) {
  data = { ...data, storeId: STORE_ID };
  store.dispatch(data);
}

export function setSeeRegister(value) {
  dispatch({
    type: TYPES.SET_SEE,
    value
  });
}

export function cleanRegister() {
  dispatch({
    type: TYPES.CLEAN_REGISTER
  });
}
export function setTipoIntegracao(value) {
  dispatch({
    type: TYPES.SET_INTEGRACAO,
    value,
  });
}

export function setTipoPlataformas(value) {
  dispatch({
    type: TYPES.SET_PLATAFORMAS,
    value,
  });
}

export function cleanAll() {
  dispatch({
    type: TYPES.CLEAN_ALL
  });
}

export function setItemTipoIntegracao(value) {
  dispatch({
    type: TYPES.SET_TIPO_INTEGRACAO,
    value
  })
}

export function setParametrosDePara(value) {
  dispatch({
    type: TYPES.SET_PARAMETROS_DE_PARA,
    value
  })
}

export function cleanItem() {
  dispatch({
    type: TYPES.CLEAN_ITEM
  });
}

export function setItem(value) {
  dispatch({
    type: TYPES.SET_ITEM,
    value,
  });
}

export function setItemField(field, value) {
  dispatch({
    type: TYPES.SET_ITEM_FIELD,
    field,
    value,
  });
}

export function setCredenciais(value) {
  dispatch({
    type: TYPES.SET_CREDENCIAIS,
    value,
  });
}

export function setIntegracoes(value) {
  dispatch({
    type: TYPES.SET_INTEGRACOES,
    value,
  });
}
export function setCredenciaisFields(value, field, index) {
  dispatch({
    type: TYPES.SET_CREDENCIAIS_FIELDS,
    value,
    field,
    index
  });
}
