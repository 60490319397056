//Crie uma função de debounce que recebe um callback e um tempo em milissegundos. Essa função deve garantir que o callback só seja executado após o tempo ter passado desde a última chamada. Se a função for chamada novamente nesse meio tempo, o tempo é resetado.

export function debounce(callback, time) {
    let timeout;
    return function(...args) {
        const later = () => {
            clearTimeout(timeout);
            callback(...args);
        };
        clearTimeout(timeout);
        timeout = setTimeout(later, time);
    }
}