import "./components/sidebar/subMenu/styles.css";
import * as AiIcons from "react-icons/ai";
import * as BsIcons from "react-icons/bs";
import * as IoIcons from "react-icons/io5";
import * as BiIcons from "react-icons/bi";
import * as RiIcons from "react-icons/ri";
import * as FiIcons from "react-icons/fi";
import * as FaIcons from "react-icons/fa";
import * as VsIcons from "react-icons/vsc";
import * as MdIcons from "react-icons/md";
import * as HiIcons from "react-icons/hi";
import * as CgIcons from "react-icons/cg";
import * as CiIcons from "react-icons/ci";
import * as TbIcons from "react-icons/tb";

import * as actions from "./store/modules/routes/actions";

import { cripto } from "cripto";

import Dashboard from "./views/dashboard/dashboard";
import ProdutosRibercred from "./views/produtosRibercred/produtosRibercred";
import ConvenioInstituicao from "./views/convenioInstituicao/convenioInstituicao";
import ProdutosInstituicoes from "./views/produtosInstituicoes/produtosInstituicoes";
import ConveniosRibercred from "./views/conveniosRibercred/conveniosRibercred";
import OperacaoRibercred from "./views/operacaoRibercred/operacaoRibercred";
import StatusRibercred from "./views/statusRibercred/statusRibercred";
import VinculoCentroDeCusto from "./views/vinculoCentroDeCusto/vinculoCentroDeCusto";
import CentroDeCusto from "./views/centroCusto/centroCusto";
import TipoOperacaoInstituicao from "./views/operacaoInstituicao/operacaoInstituicao";
import StatusInstituicao from "./views/statusInstituicao/statusInstituicao";
import Hierarquia from "./views/hierarquia/hierarquia";
import Personas from "./views/personas/personas";
import Inconsistencias from "./views/inconsistencias/inconsistencias";
import Comissao from "./views/comissao/comissao";
import CampanhaPremiacao from "./views/campanhaPremiacao/campanhaPremiacao";
import TipoComissao from "./views/tipoComissao/tipoComissao";
import TipoDocumento from "./views/tipoDocumento/tipoDocumento";
import TipoParceira from "./views/tipoParceria/tipoParceria";
import Propostas from "./views/propostas/propostas";
import PerfilCliente from "./views/perfilCliente/perfilCliente";
import Usuario from "./views/usuario/usuario";
import LayoutParametrizacao from "./views/layoutParametrizacao/layoutParametrizacao";
import Grupos from "./views/grupos/grupos";
import UploadComissao from "./views/uploadComissao/uploadComissao";
import PerfilAcesso from "./views/perfilAcesso/perfilAcesso";
import ContasReceber from "./views/contasReceber/contasReceber";
import ContasAPagar from "./views/contasAPagar/contasAPagar";
import AntContasReceber from "./views/antecipacaoContasReceber/antecipacaoContasReceber";
import DivisaoComissao from "./views/divisaoComissao/divisaoComissao";
import FormalizacaoLoja from "./views/formalizacaoLoja/formalizacaoLoja";
import FormalizacaoMatriz from "./views/formalizacaoMatriz/formalizacaoMatriz";
import TipoFormalizacaoRiber from "./views/tipoFormalizacaoRibercred/TipoFormalizacaoRiber";
import TipoFormalizacaoInst from "./views/tipoFormalizacaoInstituicao/TipoFormalizacaoInst";
import IncosistenciaProducao from "./views/inconsistenciaProducao/IncosistenciaProducao";
import IncosistenciaComissao from "./views/inconsistenciaComissao/IncosistenciaComissao";
import PendenciaRibercred from "./views/pendenciaRibercred/pendenciaRibercred";
import PendenciaInstituicao from "./views/pendenciaInstituicao/pendenciaInstituicao";
import Home from "./views/home/Home";
import ParametrosAutomacao from "./views/parametrosAutomacao/parametrosAutomacao";
import AceiteCP from "./views/aceiteCp/aceiteCp";
import ConferenciaCP from "./views/conferenciaCp/conferenciaCp";
import IncosistenciaComissaoRecebida from "./views/inconsistenciaComissaoRecebida/IncosistenciaComissaoRecebida";
import VinculoLoja from "./views/vinculoLoja/vinculoLoja";
import AgrupamentoPropostas from "./views/agrupamentoPropostas/agrupamentoPropostas";
import AgrupamentoPropostasCentroCusto from "./views/agrupamentoPropostasCentroCusto/agrupamentoPropostas";
import ConferenciaCR from "./views/conferenciaCr/conferenciaCr";
import IncosistenciaProposta from "./views/inconsistenciaProposta/IncosistenciaProposta";
import IncosistenciaFormalizacao from "./views/inconsistenciaFormalizacao/IncosistenciaFormalizacao";
import ContaGerencial from "./views/contaGerencial/contaGerencial";

export const allRoutes = [
  {
    title: "Home",
    path: "/",
    component: Home,
    visibledDashboard: true,
    miniMenu: true,
    id: "FCB6A239-8AF3-47E4-B818-3E58CDF1A4E4",
    icon: <AiIcons.AiOutlineHome className="icons" />,
  },
  {
    title: "Dashboard",
    path: "/dashboard",
    component: Dashboard,
    visibledDashboard: true,
    miniMenu: true,
    id: "41BBC09F-D982-43E4-BE89-451D56F96F6D",
    icon: <BsIcons.BsBarChartLine className="icons" />,
  },
  {
    title: "Propostas",
    path: "/propostas",
    component: Propostas,
    visibledDashboard: true,
    miniMenu: true,
    id: "A9F6CA34-417E-4F8E-B3C1-DEB47FAB1134",
    icon: <AiIcons.AiOutlineFileProtect className="icons" />,
  },
  {
    title: "Personas",
    path: "/personas",
    component: Personas,
    visibledDashboard: true,
    miniMenu: true,
    id: "DD3140A5-33A3-49DE-A0BF-9C0326F0F607",
    icon: <BsIcons.BsPeople className="icons" />,
  },
  {
    title: "Produtos",
    visibledDashboard: true,
    miniMenu: true,
    icon: <CiIcons.CiShoppingTag className="icons" />,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Produto Ribercred",
        path: "/produtoribercred",
        component: ProdutosRibercred,
        id: "6A0DA6D9-21C7-45E3-BFF9-3005829E552F",
        icon: <BiIcons.BiBuildings className="icons-submenu" />,
      },
      {
        title: "Produto Instituição",
        path: "/produtoinstituicao",
        component: ProdutosInstituicoes,
        id: "0868FE11-9E75-489C-9A48-94A217B6639C",
        icon: <AiIcons.AiOutlineBank className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Tipos de Operação",
    icon: <BsIcons.BsBookmark className="icons" />,
    visibledDashboard: true,
    miniMenu: true,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Operação Ribercred ",
        path: "/operacaoribercred",
        component: OperacaoRibercred,
        id: "59711D78-556D-469F-A641-04FA9FD6AA04",
        icon: <FiIcons.FiShoppingBag className="icons-submenu" />,
      },
      {
        title: "Operação Instituição",
        path: "/operacaoinstituicao",
        id: "E6F05E23-69A2-4BB6-8C80-B08D2188D43F",
        component: TipoOperacaoInstituicao,
        icon: <BsIcons.BsFlag className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Tipo Pendência Físico",
    icon: <AiIcons.AiOutlineFileExclamation className="icons" />,
    visibledDashboard: true,
    miniMenu: true,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Pendência Ribercred ",
        path: "/pendenciaRibercred",
        component: PendenciaRibercred,
        id: "0E92F4C0-7959-4E83-8634-7B56EA59E1D5",
        icon: <BiIcons.BiHome className="icons-submenu" />,
      },
      {
        title: "Pendência Instituição",
        path: "/pendenciaInstituicao",
        id: "C9707568-05EA-4B27-B072-6A80A27B76EE",
        component: PendenciaInstituicao,
        icon: <BiIcons.BiHome className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Vinculo Centro de Custo",
    path: "/vinculoCentrodecusto",
    component: VinculoCentroDeCusto,
    visibledDashboard: true,
    miniMenu: true,
    id: "74044A0F-0BA0-4213-A69F-7E0938FAEAF4",
    icon: <HiIcons.HiOutlineCurrencyDollar className="icons" />,
  },
  {
    title: "Centro de Custo",
    path: "/centrodecusto",
    component: CentroDeCusto,
    visibledDashboard: true,
    miniMenu: true,
    id: "B520BD45-C493-438A-A318-D6CE3EDA4875",
    icon: <HiIcons.HiOutlineCurrencyDollar className="icons" />,
  },
  {
    title: "Layout Parametrização",
    path: "/layoutparametrizacao",
    component: LayoutParametrizacao,
    visibledDashboard: true,
    miniMenu: true,
    id: "3AA128E3-2923-4060-AB5E-35D6E350FDB6",
    icon: <AiIcons.AiOutlineLayout className="icons" />,
  },
  {
    title: "Contas",
    visibledDashboard: true,
    miniMenu: true,
    icon: <BsIcons.BsGraphUp className="icons" />,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Conta Gerencial",
        path: "/contaGerencial",
        component: ContaGerencial,
        id: "37626604-730F-4D13-BAAD-3660EE99F770",
        icon: <MdIcons.MdManageAccounts className="icons" />
      },
      {
        title: "Contas a Receber",
        path: "/contasareceber",
        component: ContasReceber,
        id: "B27A6DCD-2463-4ED0-88DB-A9FFC49EFC46",
        icon: <BiIcons.BiWallet className="icons-submenu" />,
      },
      {
        title: "Contas a Pagar",
        path: "/contasapagar",
        component: ContasAPagar,
        id: "D5C6EC1B-9F08-4252-BFE4-FB7FC2786B21",
        icon: <BiIcons.BiMoney className="icons-submenu" />,
      },
      {
        title: "Antecipação Contas A Receber",
        path: "/antecipacaoContasReceber",
        component: AntContasReceber,
        id: "1422CFFE-FFB1-411F-9702-9771AB198F64",
        icon: <AiIcons.AiOutlineFund className="icons-submenu" />,
      },
      {
        title: "Aceite CP",
        path: "/aceiteCp",
        component: AceiteCP,
        id: "057DF3F2-1672-41B6-B459-8088676A3E1F",
        icon: <IoIcons.IoMegaphoneOutline className="icons-submenu" />,
      },
      {
        title: "Conferência CP",
        path: "/conferenciaCp",
        component: ConferenciaCP,
        id: "FD4F9987-89A8-4FBC-BB85-0B7CEBF7C391",
        icon: <IoIcons.IoMegaphoneOutline className="icons-submenu" />,
      },
      {
        title: "Conferência CR",
        path: "/conferenciaCr",
        component: ConferenciaCR,
        id: "E94A9B51-5181-4022-93F4-CD51053FE168",
        icon: <IoIcons.IoMegaphoneOutline className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Comissões",
    visibledDashboard: true,
    miniMenu: true,
    icon: <AiIcons.AiOutlinePercentage className="icons" />,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Agrupamento Comissão Recebida",
        path: "/agrupamentoComissaoRecebida",
        component: AgrupamentoPropostas,
        id: "3C563F88-499A-4831-A355-5DCA31D25145",
        icon: <AiIcons.AiOutlineFund className="icons-submenu" />,
      },
      {
        title: "Agrupamento Comissão Recebida Centro Custo",
        path: "/agrupamentoComissaoRecebidaCentroCusto",
        component: AgrupamentoPropostasCentroCusto,
        id: "83328FE4-444F-4160-BE94-585308571D17",
        icon: <AiIcons.AiOutlineFund className="icons-submenu" />,
      },
      {
        title: "Divisão de Comissão",
        path: "/divisaoComissao",
        component: DivisaoComissao,
        id: "E387FBB0-5BB0-410C-9FEF-37D356085BDD",
        icon: <AiIcons.AiOutlineFund className="icons-submenu" />,
      },
      {
        title: "Tabela de Comissão",
        path: "/tabeladecomissao",
        id: "B2C1117A-E0E3-46E9-86D2-3B0A3A67AD66",
        icon: <RiIcons.RiTableLine className="icons-submenu" />,
        component: Comissao,
      },
      {
        title: "Campanha de Premiação",
        path: "/campanhadepremiacao",
        component: CampanhaPremiacao,
        id: "D7191568-5BA9-4844-9E68-CFD18C9BD8E8",
        icon: <FiIcons.FiTarget className="icons-submenu" />,
      },
      {
        title: "Tipo de Comissão",
        path: "/tipodecomissao",
        component: TipoComissao,
        id: "24BCE9EB-9C97-4410-A60C-C7A7C79DDDC6",
        icon: <IoIcons.IoMegaphoneOutline className="icons-submenu" />,
      },
      {
        title: "Vinculo Loja",
        path: "/vinculoloja",
        component: VinculoLoja,
        id: "D8F08E3B-50A4-4CB0-A9F5-0E92D511CFA2",
        icon: <IoIcons.IoMegaphoneOutline className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Segurança",
    visibledDashboard: true,
    miniMenu: true,
    icon: <MdIcons.MdSecurity className="icons" />,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Usuário",
        path: "/usuario",
        component: Usuario,
        visibledDashboard: true,
        miniMenu: true,
        id: "8C5A8481-864B-40CF-8F7B-99CDE8E80F39",
        icon: <FaIcons.FaUserTie className="icons" />,
      },
      {
        title: "Perfil de Acesso",
        path: "/perfilDeAcesso",
        component: PerfilAcesso,
        visibledDashboard: true,
        miniMenu: true,
        id: "C55037E7-CEE4-4CB0-83E7-6C092A97A79D",
        icon: <CgIcons.CgScreen className="icons" />,
      },
    ],
  },
  {
    title: "Convênios",
    icon: <BsIcons.BsPencilSquare className="icons" />,
    visibledDashboard: true,
    miniMenu: true,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Convênio Ribercred",
        path: "/convenioribercred",
        component: ConveniosRibercred,
        id: "F0AEB4B4-9060-40B0-9E06-6C8B23FAE5E5",
        icon: <HiIcons.HiOutlineClipboard className="icons-submenu" />,
      },
      {
        title: "Convênio Instituição",
        path: "/convenioinstituicao",
        component: ConvenioInstituicao,
        id: "C03207C2-1843-4E45-872A-88C2360E69E6",
        icon: <FaIcons.FaClipboard className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Status",
    visibledDashboard: true,
    miniMenu: true,
    icon: <TbIcons.TbCheckbox className="icons" />,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Status Ribercred",
        path: "/statusribercred",
        component: StatusRibercred,
        id: "D0A70294-141A-4FB2-BFEC-7261B9777B8D",
        icon: <AiIcons.AiOutlineAlert className="icons-submenu" />,
      },
      {
        title: "Status Instituição",
        path: "/statusinstituicao",
        component: StatusInstituicao,
        id: "1F831FF9-7DA7-4368-BC17-F4E23C0342F0",
        icon: <AiIcons.AiTwotoneAlert className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Tipos de Formalizações",
    visibledDashboard: true,
    miniMenu: true,
    icon: <TbIcons.TbBoxMultiple className="icons" />,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Tipo Formalização Ribercred",
        path: "/tipoFormalizacaoRiber",
        component: TipoFormalizacaoRiber,
        id: "F08AD59C-8F7A-4ACB-AC7A-0D96AD4B2562",
        icon: <BiIcons.BiHome className="icons-submenu" />,
      },
      {
        title: "Tipo Formalização Instituição",
        path: "/tipoFormalizacaoInst",
        component: TipoFormalizacaoInst,
        id: "5A44A07D-967F-41DB-8D80-E042C057F195",
        icon: <BiIcons.BiHome className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Upload de Planilhas",
    path: "/uploadcomissoes",
    component: UploadComissao,
    visibledDashboard: true,
    miniMenu: true,
    id: "38A763C2-3FEA-4FA4-9C05-DBA154B3D0D6",
    icon: <MdIcons.MdOutlineDriveFolderUpload className="icons" />,
  },
  {
    title: "Inconsistências Sistema",
    visibledDashboard: true,
    miniMenu: true,
    icon: <BsIcons.BsExclamationTriangle className="icons" />,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Inconsistência Sistema Produção",
        path: "/inconsistenciaProducao",
        component: IncosistenciaProducao,
        id: "05D054C0-DB71-4B74-8266-E883F4711FC9",
        icon: <AiIcons.AiOutlineHome className="icons-submenu" />,
      },
      {
        title: "Inconsistência Sistema Comissão",
        path: "/inconsistenciaComissao",
        component: IncosistenciaComissao,
        id: "10085F83-611D-4E98-847D-345B5354F1DA",
        icon: <AiIcons.AiOutlineHome className="icons-submenu" />,
      },
      {
        title: "Inconsistência Sistema Comissão Recebida",
        path: "/inconsistenciaComissaoRecebida",
        component: IncosistenciaComissaoRecebida,
        id: "DA9EF765-AB27-41BE-9C14-ED1937EC6A66",
        icon: <AiIcons.AiOutlineHome className="icons-submenu" />,
      },
      {
        title: "Inconsistência Sistema Proposta",
        path: "/inconsistenciaProposta",
        component: IncosistenciaProposta,
        id: "E66EA69E-1C4E-499A-A52A-69B0333C62C5",
        icon: <AiIcons.AiOutlineHome className="icons-submenu" />,
      },
      {
        title: "Inconsistência Sistema Formalização",
        path: "/inconsistenciaFormalização",
        component: IncosistenciaFormalizacao,
        id: "42CFE5F2-D0D7-41A3-AA86-83AEFEC51998",
        icon: <AiIcons.AiOutlineHome className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Formalizações",
    visibledDashboard: true,
    miniMenu: true,
    icon: <MdIcons.MdForwardToInbox className="icons" />,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Formalização Loja",
        path: "/formalizacaoLoja",
        component: FormalizacaoLoja,
        id: "B8FB20C5-A8E5-4BE7-8338-7134F2E606C4",
        icon: <AiIcons.AiOutlineHome className="icons-submenu" />,
      },
      {
        title: "Formalização Matriz",
        path: "/formalizacaoMatriz",
        component: FormalizacaoMatriz,
        id: "813BCE5F-5A54-4F75-B7EA-AEEF52285D52",
        icon: <AiIcons.AiOutlineHome className="icons-submenu" />,
      },
    ],
  },
  {
    title: "Parâmetros de automação",
    path: "/parametrosDeAutomacao",
    component: ParametrosAutomacao,
    visibledDashboard: true,
    miniMenu: true,
    id: "D2D23083-83F2-4CE2-988E-077127F0D9DF",
    icon: <BsIcons.BsGear className="icons" />,
  },
  {
    title: "Outras Funções",
    visibledDashboard: true,
    miniMenu: true,
    icon: <TbIcons.TbLayoutGridAdd className="icons" />,
    iconClosed: <RiIcons.RiArrowDownSLine />,
    iconOpened: <RiIcons.RiArrowUpSLine />,
    subNav: [
      {
        title: "Hierarquias",
        path: "/hierarquias",
        component: Hierarquia,
        id: "EBE5B00C-6F53-4E3F-9700-55A95B52DFF3",
        icon: <RiIcons.RiAwardLine className="icons-submenu" />,
      },
      {
        title: "Grupos",
        path: "/grupos",
        component: Grupos,
        id: "5B2EA770-B619-45AF-AF0B-381F435557EC",
        icon: <RiIcons.RiAwardLine className="icons-submenu" />,
      },
      {
        title: "Consistências",
        path: "/consistencias",
        component: Inconsistencias,
        id: "7DFEE358-0B13-430B-B11A-95B867836207",
        icon: <AiIcons.AiOutlineDislike className="icons-submenu" />,
      },
      {
        title: "Tipo de Parceria",
        path: "/tipodeparceria",
        component: TipoParceira,
        id: "3759DFE0-3252-4F6F-851D-4FEE7D7B6512",
        icon: <IoIcons.IoExtensionPuzzleOutline className="icons-submenu" />,
      },
      {
        title: "Tipo de Documento",
        path: "/tipodedocumento",
        component: TipoDocumento,
        id: "2422801B-EC35-4B8E-80C4-7016CB762B47",
        icon: <BiIcons.BiUserCircle className="icons" />,
      },
      {
        title: "Perfil do Cliente",
        path: "/perfildocliente",
        component: PerfilCliente,
        id: "62533669-3BBD-41B0-953F-E0E433DC9D0B",
        icon: <VsIcons.VscPerson className="icons" />,
      },
    ],
  },
];

export function currentRoutes(userKeys) {
  let textEncript = localStorage.getItem("userControllkeys");
  let userControllKeys = textEncript
    ? JSON.parse(cripto.toText(textEncript))
    : userKeys;

  const routes = allRoutes.filter((route) => {
    if (route.title === "Home") {
      return route;
    }
    if (route.subNav) {
      var newSubnav = [];
      route.subNav.forEach((subnav) => {
        if (userControllKeys.find((e) => e.id.toUpperCase() === subnav.id)) {
          newSubnav.push(subnav);
        }
      });
      if (newSubnav.length > 0) {
        route.subNav = newSubnav;
        return route;
      }
    } else {
      if (userControllKeys.find((e) => e.id.toUpperCase() === route.id)) {
        return route;
      }
    }
    return null;
  });

  return actions.setRoutes(routes);
}
