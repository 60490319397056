import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from "react";
import { Input } from "reactstrap";
import { TbBraces } from "react-icons/tb";
import { IconButton, Tooltip } from "@material-ui/core";
import classNames from "classnames";

const InputVariable = ({
  rawValue: externalRawValue,
  onRawChange,
  variables,
  placeholder,
}) => {
  const [internalValue, setInternalValue] = useState("");
  const [internalRawValue, setInternalRawValue] = useState("");
  const [invalidVariables, setInvalidVariables] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const inputRef = useRef(null);
  const dropdownRef = useRef(null);

  const memoizedVariables = useMemo(() => variables, [variables]);

  useEffect(() => {
    const rawToValue = (raw) => {
      let newValue = raw;
      memoizedVariables.forEach((variable) => {
        const regex = new RegExp(
          `\\{\\{${variable.campoEquivalente}\\}\\}`,
          "g"
        );
        newValue = newValue.replace(regex, `{{${variable.descricao}}}`);
      });
      return newValue;
    };

    if (
      externalRawValue !== undefined &&
      externalRawValue !== internalRawValue
    ) {
      const newValue = rawToValue(externalRawValue);
      setInternalValue(newValue);
      setInternalRawValue(externalRawValue);
    }
  }, [externalRawValue, memoizedVariables, internalRawValue]);

  const valueToRaw = useCallback(
    (value) => {
      let newRawValue = value;
      memoizedVariables.forEach((variable) => {
        const regex = new RegExp(`\\{\\{${variable.descricao}\\}\\}`, "g");
        newRawValue = newRawValue.replace(
          regex,
          `{{${variable.campoEquivalente}}}`
        );
      });
      return newRawValue;
    },
    [memoizedVariables]
  );

  const validateRawValue = useCallback(
    (raw) => {
      const allVariables = new Set();
      const invalidVariables = [];

      memoizedVariables.forEach((variable) => {
        const regex = new RegExp(
          `\\{\\{${variable.campoEquivalente}\\}\\}`,
          "g"
        );
        raw = raw.replace(regex, `{{${variable.descricao}}}`);
      });

      const regexDisplay = /\{\{(.*?)\}\}/g;
      let matchDisplay;
      while ((matchDisplay = regexDisplay.exec(raw)) !== null) {
        allVariables.add(matchDisplay[1]);
      }

      allVariables.forEach((variableName) => {
        const isValidVariable = memoizedVariables.some(
          (v) => v.descricao === variableName
        );
        if (!isValidVariable) {
          invalidVariables.push(variableName);
        }
      });

      setInvalidVariables(invalidVariables);
    },
    [memoizedVariables]
  );

  useEffect(() => {
    const newRawValue = valueToRaw(internalValue);
    validateRawValue(newRawValue);

    if (onRawChange && newRawValue !== internalRawValue) {
      onRawChange(newRawValue);
      setInternalRawValue(newRawValue);
    }
  }, [
    internalValue,
    onRawChange,
    internalRawValue,
    valueToRaw,
    validateRawValue,
  ]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInternalValue(newValue);

    if (newValue.endsWith("{{")) {
      setIsDropdownOpen(true);
    }
  };

  const addVariable = useCallback(
    (variable) => {
      const input = inputRef.current;

      let newValue = internalValue;
      if (newValue.endsWith("{{")) {
        newValue = newValue.slice(0, -2);
      }

      const newDisplayValue = `${newValue}{{${variable.descricao}}}`;
      setInternalValue(newDisplayValue);

      input.setSelectionRange(newDisplayValue.length, newDisplayValue.length);
      setIsDropdownOpen(false);
    },
    [internalValue]
  );

  const handleKeyDown = useCallback(
    (e) => {
      const input = inputRef.current;
      const position = input.selectionStart;
      if (e.key === "Backspace") {
        const regexDisplay = /\{\{(.*?)\}\}/g;
        let matchDisplay;
        let newDisplayValue = internalValue;

        while ((matchDisplay = regexDisplay.exec(newDisplayValue)) !== null) {
          const startDisplay = matchDisplay.index;
          const endDisplay = startDisplay + matchDisplay[0].length;

          if (position > startDisplay && position <= endDisplay) {
            e.preventDefault();

            newDisplayValue =
              newDisplayValue.slice(0, startDisplay) +
              newDisplayValue.slice(endDisplay);
            setInternalValue(newDisplayValue);

            return;
          }
        }
      }
    },
    [internalValue]
  );

  const filteredVariables = useMemo(
    () =>
      variables.filter((variable) =>
        variable.descricao.toLowerCase().includes(searchTerm.toLowerCase())
      ),
    [variables, searchTerm]
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !inputRef.current.contains(event.target)
      ) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsDropdownOpen, inputRef]);

  return (
    <div>
      <div
        style={{ position: "relative", display: "inline-block", width: "100%" }}
      >
        <div style={{ position: "relative", width: "100%" }}>
          <Input
            type="text"
            value={internalValue}
            onChange={handleInputChange}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            innerRef={inputRef}
            invalid={invalidVariables.length > 0}
            style={{
              padding: "10px",
              paddingBottom: "30px",
              height: "60px",
              lineHeight: "normal",
              width: "100%",
              boxSizing: "border-box",
            }}
          />
          <Tooltip title={"Adicionar parâmetros"}>
            <IconButton
              onClick={() => setIsDropdownOpen(true)}
              style={{
                position: "absolute",
                bottom: "10px",
                right: "10px",
                background: "none",
                border: "none",
                cursor: "pointer",
                padding: "0",
              }}
            >
              <TbBraces size={20} color="#5f6973" />
            </IconButton>
          </Tooltip>
          {isDropdownOpen && (
            <div
              ref={dropdownRef}
              style={{
                position: "absolute",
                backgroundColor: "white",
                border: "1px solid #ccc",
                borderRadius: "4px",
                boxShadow: "0 2px 5px rgba(0,0,0,0.15)",
                zIndex: 1000,
                maxHeight: "200px",
                overflowY: "auto",
                maxWidth: "300px",
                right: 0,
                padding: "20px",
              }}
            >
              <div className="d-flex flex-column justify-content-center">
                <span className="text-center mb-3">Chaves reservadas</span>
                <Input
                  type="text"
                  placeholder="Pesquisar"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  className="mb-4"
                />
                <div className="d-flex flex-column">
                  {filteredVariables.map((variable, idx) => (
                    <div
                      key={idx}
                      style={{ cursor: "pointer" }}
                      className={classNames(
                        "d-flex justify-content-start",
                        idx !== filteredVariables.length - 1 && "mb-3"
                      )}
                      onClick={() => {
                        addVariable(variable);
                      }}
                    >
                      <span className="text-primary mr-3">{`{{${variable.descricao}}}`}</span>
                      <span>{variable.campoEquivalente}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InputVariable;
