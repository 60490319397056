import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Modal, Row, Navbar, Col, Label, Input } from "reactstrap";
import * as controller from "../../../controllers/aceiteCpController";
import * as actions from "../../../store/modules/aceiteCp/actions";
import { Select } from "../../../components/Inputs/Input";
import { notifySuccess, notifyWarn } from "../../../components/message/message";
import { maskMoeda } from "../../../util/masks";

const ModalEditar = ({ toggle, isOpen, pagination }) => {
  const {
    item: filters,
    sistemas,
    modalEdicao,
    selectedItems,
  } = useSelector((state) => state.aceiteCp);

  async function handleConfirmar() {
    if(modalEdicao.sistemaId !== "0") {
        const success = await controller.aceite({
          ids: selectedItems || [],
          campanhaPremiacaoTipoRegraId: modalEdicao.tipoRegraId,
          campanhaPremiacaoSistemaIntegracaoId: modalEdicao.sistemaId,
        });
    
        if (success) {
          actions.cleanModalEdicao();
          await controller.get(pagination.page, pagination.pageSize, filters);
          toggle();
        }
    } else {
        notifyWarn("Selecione um sistema");
    }
  }

  const onChange = (value, field) => {
    actions.setModalEdicaoField(value, field);
  };

  useEffect(() => {
    actions.setModalEdicaoField("0", "sistemaId");
  }, []);

  return (
    <div>
      <Modal
        className="text-center"
        isOpen={isOpen}
        toggle={toggle}
        backdrop={true}
        size="lg"
      >
        <Navbar
          expand="md"
          className="w-100 mb-2"
          style={{
            height: "40px",
            background: "var(--color-gradiente-verde)",
          }}
        >
          <h5 className="mx-auto mt-2 text-white">Aceite dados</h5>
        </Navbar>
        <div className="container-fluid my-3">
          <Row>
            <Col md={4}>
              <Label>Tipo Pagamento</Label>
              <Select
                first="Selecione"
                value={{ value: modalEdicao.tipoRegraId }}
                select={{ value: "id", label: "descricao" }}
                options={[
                  {
                    id: "3EF7CAA0-AF73-440C-801B-2B5788E69C37",
                    descricao: "PRODUTIVIDADE",
                  },
                  {
                    id: "BE21DBC5-6C12-47B0-8C02-3D92111B3379",
                    descricao: "SUPERAÇÃO",
                  },
                  {
                    id: "D139F68F-6E22-46E4-B5D4-268D31FC9ECC",
                    descricao: "REPASSE",
                  },
                ]}
                isSearchable={true}
                autoComplete="off"
                disabled
              />
            </Col>
            <Col md={4}>
              <Label>Sistema</Label>
              <Select
                first="Selecione"
                value={{ value: modalEdicao.sistemaId }}
                select={{ value: "id", label: "nome" }}
                options={sistemas}
                onChange={(e) => {
                  onChange(e.value, "sistemaId");
                }}
                isSearchable={true}
                autoComplete="off"
              />
            </Col>
            <Col md={4}>
              <Label>Total Pago</Label>
              <Input
                type="text"
                value={maskMoeda(modalEdicao.valor?.toFixed(2))}
                disabled
              />
            </Col>
          </Row>
          <Row className="justify-content-center mt-5">
            <Col md={2}>
              <Button
                style={{
                  width: "100%",
                  height: "38px",
                  backgroundColor: "var(--color-laranja)",
                  borderColor: "var(--color-laranja)",
                }}
                onClick={() => {
                  toggle();
                }}
              >
                Cancelar
              </Button>
            </Col>
            <Col md={2}>
              <Button
                className="purpleButton"
                style={{
                  width: "100%",
                  height: "38px",
                }}
                onClick={() => {
                  handleConfirmar();
                }}
              >
                Salvar
              </Button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};

export default ModalEditar;
