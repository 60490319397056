import React, { useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import * as BsIcons from "react-icons/bs";
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Modal, Col, Navbar } from "reactstrap";
import { COLUMNS_DETALHAMENTO } from "../constants/columns";
import * as controller from "../../../controllers/conferenciaCpController";

const ModalDetalhamento = ({ isOpen, toggle, id }) => {
  const { contaPagarAgrupadoItens } = useSelector(
    (state) => state.conferenciaCp
  );
  const columns = useMemo(() => COLUMNS_DETALHAMENTO, []);
  const data = contaPagarAgrupadoItens;
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
      },
      useGlobalFilter,
      useSortBy
    );

  useEffect(() => {
    if (isOpen) {
      controller.getContaPagarAgrupadoItens(id);
    }
  }, [id, isOpen]);

  return (
    <Modal
      className="text-center"
      isOpen={isOpen}
      toggle={toggle}
      backdrop={true}
      size="xl"
    >
      <Navbar
        expand="md"
        className="w-100 mb-2"
        style={{
          height: "40px",
          background: "var(--color-gradiente-verde)",
        }}
      >
        <h5 className="ml-auto mr-auto mt-2 text-white">
          Detalhamento Conferência
        </h5>
      </Navbar>
      <Col>
        <div  className="d-flex flex-grow-1 mw-100 overflow-auto my-3">
          <table {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th style={{ minWidth: "150px" }}>
                      <span className="float-left">
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsIcons.BsArrowDown
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  title: undefined,
                                })
                              )}
                            />
                          ) : (
                            <BsIcons.BsArrowUp
                              id="OrdenarAsc"
                              {...column.getHeaderProps(
                                column.getSortByToggleProps({
                                  title: undefined,
                                })
                              )}
                            />
                          )
                        ) : (
                          <BsIcons.BsArrowDownUp
                            {...column.getHeaderProps(
                              column.getSortByToggleProps({ title: undefined })
                            )}
                          />
                        )}
                      </span>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr className="text-center" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Col>
    </Modal>
  );
};

export default ModalDetalhamento;
