import React from "react";
import "../../style.css";
import { Col, TabContent, TabPane } from "reactstrap";
import Search from "./tabs/search";
import Breadcrumb from "../../components/breadcrumbs";

export default function UploadComissao() {

  return (
    <>
      <Col>
        <Breadcrumb activeTab={"Search"} />
      </Col>
      <TabContent style={{ width: "100%" }} activeTab={"Search"}>
        <TabPane tabId="Search">
          <br />
          <Search />
        </TabPane>
      </TabContent>
    </>
  );
}
