import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { BsArrowUp, BsArrowDown, BsArrowDownUp } from "react-icons/bs";
import { AiOutlineDownload, AiOutlineEye } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import {
  useTable,
  useSortBy,
  usePagination,
  useGlobalFilter,
} from "react-table";
import { Button, Col, Input, Label, Row, FormGroup, Card } from "reactstrap";
import { COLUMNS } from "../constants/columns";
import * as actions from "../../../store/modules/conferenciaCp/actions";
import * as controller from "../../../controllers/conferenciaCpController";
import { Select } from "../../../components/Inputs/Input";
import ModalDetalhamento from "../components/ModalDetalhamento";
import { BiEdit } from "react-icons/bi";
import { TbCheckbox } from "react-icons/tb";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import ModalEdicao from "../components/ModalEdicao";

const Search = () => {
  const { search, contasPagarAgrupado, pagination, optionsSelect, download } =
    useSelector((state) => state.conferenciaCp);
  const columns = useMemo(() => COLUMNS, []);
  const data = contasPagarAgrupado;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    gotoPage,
    state,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { pageSize } = state;
  const [initialPage, setInitialPage] = useState(false);
  const [selectedPage, setSelectedPage] = useState(null);
  const [isAllContasPagarActive, setIsAllContasPagarActive] = useState(false);
  const [isModalDetalhamentoOpen, setIsModalDetalhamentoOpen] = useState(false);
  const [isModalEdicaoOpen, setIsModalEdicaoOpen] = useState(false);
  const [comissaoId, setComissaoId] = useState("0");
  const toggleDetalhamento = () =>
    setIsModalDetalhamentoOpen(!isModalDetalhamentoOpen);
  const toggleEdicao = () => setIsModalEdicaoOpen(!isModalEdicaoOpen);

  const handlePageClick = async (e) => {
    const pageSelected = e.selected;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    await controller.getContasPagarAgrupada(search, pageSize, pageSelected + 1);
  };

  useEffect(() => {
    if (pagination.tamanhoPagina) {
      setPageSize(pagination.tamanhoPagina);
    }
  }, [pagination, setPageSize]);

  useEffect(() => {
    controller.getFiltros();
    controller.getContasPagarAgrupada();
  }, []);

  async function handleInitialPage(pageSize) {
    const pageSelected = 0;
    setInitialPage(false);
    setSelectedPage(pageSelected);
    gotoPage(pageSelected);
    await controller.getContasPagarAgrupada(search, pageSize, pageSelected + 1);
  }

  function onChange(value, field) {
    actions.setSearch(field, value);
  }

  async function getAll() {
    await controller.getContasPagarAgrupada(search, pageSize, 1);
    gotoPage(0);
    setInitialPage(true);
    setSelectedPage(0);
  }

  function seeRegister(id) {
    setComissaoId(id);
    toggleDetalhamento();
  }

  function editRegister(id) {
    setComissaoId(id);
    const itemSelected = contasPagarAgrupado.find((e) => e.id === id);
    actions.setItem({
      id: itemSelected.id,
      sistemaId: itemSelected.sistemaId,
      tipoRegraId: itemSelected.tipoRegraId,
      valor: itemSelected.valor,
    });
    toggleEdicao();
  }

  async function downloadRegister(id) {
    const success = await controller.downloadPlanilha(id);
    if (success) {
      document.getElementById("download").click();
    }
  }

  async function estornarRegister(id) {
    await controller.estornarConta(id);
    await controller.getContasPagarAgrupada(search, pageSize, selectedPage + 1);
  }

  async function aprovarRegister(id) {
    await controller.aprovarConta(id);
    await controller.getContasPagarAgrupada(search, pageSize, selectedPage + 1);
  }

  function handleSelectContaPagar(id) {
    let index = contasPagarAgrupado.findIndex((e) => e.id === id);
    contasPagarAgrupado[index].isSelected =
      !contasPagarAgrupado[index].isSelected;
    actions.setContasPagarAgrupado(contasPagarAgrupado);
    if (
      contasPagarAgrupado.filter((conta) => conta.status.toUpperCase() === "PENDENTE").every((e) => e.isSelected)
      && contasPagarAgrupado.filter((conta) => conta.status.toUpperCase() === "PENDENTE" && conta.isSelected).length > 0
    ) {
      setIsAllContasPagarActive(true);
    } else {
      setIsAllContasPagarActive(false);
    }
  }

  function handleSelectAllContasPagar() {
    setIsAllContasPagarActive(!isAllContasPagarActive);
    if (!isAllContasPagarActive) {
      contasPagarAgrupado
        .filter((conta) => conta.status.toUpperCase() === "PENDENTE")
        .forEach((e) => {
          e.isSelected = true;
        });
    } else {
      contasPagarAgrupado
        .filter((conta) => conta.status.toUpperCase() === "PENDENTE")
        .forEach((e) => {
          e.isSelected = false;
        });
    }
  }

  return (
    <>
      <Col>
        <Card className="pt-3 pb-2 mb-5 ml-4">
          <div className="container-fluid px-3">
            <Row>
              <Col className="d-flex flex-column">
                <div className="mb-3">
                  <Label
                    style={{
                      fontWeight: "bold",
                      fontSize: "18px",
                      marginBottom: "8px",
                    }}
                  >
                    Pesquisar
                  </Label>
                </div>
                <Row>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Campanha</Label>
                      <Select
                        first="Selecione"
                        value={{ value: search.campanhaPremiacaoId }}
                        select={{ value: "id", label: "descricao" }}
                        options={optionsSelect.campanhas}
                        onChange={(e) => {
                          onChange(e.value, "campanhaPremiacaoId");
                        }}
                        isSearchable={true}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>

                  <Col md={3}>
                    <FormGroup>
                      <Label>Empresa</Label>
                      <Select
                        first="Selecione"
                        value={{ value: search.empresaId }}
                        select={{ value: "id", label: "razaoSocialEmpresa" }}
                        options={optionsSelect.empresas}
                        onChange={(e) => {
                          onChange(e.value, "empresaId");
                        }}
                        isSearchable={true}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Tipo Pagamento</Label>
                      <Select
                        first="Selecione"
                        value={{ value: search.tipoRegraId }}
                        select={{ value: "id", label: "descricao" }}
                        options={optionsSelect.tiposPagamento}
                        onChange={(e) => {
                          onChange(e.value, "tipoRegraId");
                        }}
                        isSearchable={true}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Data Aceite</Label>
                      <Input
                        type="date"
                        value={search.dataAceito}
                        onChange={(e) => onChange(e.target.value, "dataAceito")}
                        autoComplete="true"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Sistema</Label>
                      <Select
                        first="Selecione"
                        value={{ value: search.sistemaId }}
                        select={{ value: "id", label: "nome" }}
                        options={optionsSelect.sistemas}
                        onChange={(e) => {
                          onChange(e.value, "sistemaId");
                        }}
                        isSearchable={true}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                  <Col md={3}>
                    <FormGroup>
                      <Label>Status</Label>
                      <Select
                        first="Selecione"
                        value={{ value: search.statusId }}
                        select={{ value: "id", label: "descricao" }}
                        options={optionsSelect.status}
                        onChange={(e) => {
                          onChange(e.value, "statusId");
                        }}
                        isSearchable={true}
                        autoComplete="off"
                      />
                    </FormGroup>
                  </Col>
                </Row>
                <Row className="align-items-center mt-1">
                  <Col md={2}>
                    <Button
                      style={{
                        width: "100%",
                        height: "38px",
                        backgroundColor: "var(--color-branco)",
                        borderColor: "var(--color-laranja)",
                        color: "var(--color-laranja)",
                      }}
                      onClick={actions.cleanSearch}
                    >
                      Limpar
                    </Button>
                  </Col>
                  <Col md={2}>
                    <Button
                      className="purpleButton"
                      style={{
                        width: "100%",
                        height: "38px",
                      }}
                      onClick={() => getAll()}
                    >
                      Buscar
                    </Button>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="d-flex flex-grow-1 mw-100 overflow-auto m-5">
                  <table {...getTableProps()}>
                    <thead>
                      {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                          <th style={{ textAlign: "left", minWidth: "20px" }}>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <input
                                style={{ display: "inline-block" }}
                                type="checkbox"
                                checked={isAllContasPagarActive}
                                onClick={() => handleSelectAllContasPagar()}
                              />
                            </div>
                          </th>
                          {headerGroup.headers.map((column) => (
                            <th key={column.id} style={{ minWidth: "160px" }}>
                              <span className="float-left">
                                {column.isSorted ? (
                                  column.isSortedDesc ? (
                                    <BsArrowDown
                                      {...column.getHeaderProps(
                                        column.getSortByToggleProps({
                                          title: undefined,
                                        })
                                      )}
                                    />
                                  ) : (
                                    <BsArrowUp
                                      id="OrdenarAsc"
                                      {...column.getHeaderProps(
                                        column.getSortByToggleProps({
                                          title: undefined,
                                        })
                                      )}
                                    />
                                  )
                                ) : (
                                  <BsArrowDownUp
                                    {...column.getHeaderProps(
                                      column.getSortByToggleProps({
                                        title: undefined,
                                      })
                                    )}
                                  />
                                )}
                              </span>
                              {column.render("Header")}
                            </th>
                          ))}
                          <th style={{ textAlign: "center" }}>Ações</th>
                        </tr>
                      ))}
                    </thead>

                    <tbody {...getTableBodyProps()}>
                      {page.map((row) => {
                        prepareRow(row);
                        return (
                          <tr
                            className="text-center"
                            {...row.getRowProps()}
                            index={row.original.id}
                          >
                            <td>
                              {row.original.status.toUpperCase() ===
                                "PENDENTE" && (
                                <input
                                  type="checkbox"
                                  style={{ display: "flex", marginLeft: "7px" }}
                                  checked={
                                    row.original.isSelected ? true : false
                                  }
                                  defaultChecked={false}
                                  onChange={() =>
                                    handleSelectContaPagar(row.original.id)
                                  }
                                />
                              )}
                            </td>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                            <td>
                              <div className="d-flex justify-content-center gap-2">
                                <AiOutlineEye
                                  style={{
                                    fontSize: "1.2rem",
                                    cursor: "pointer",
                                    color: "#C4C7CA",
                                    marginRight: "7px",
                                  }}
                                  onClick={() => seeRegister(row.original.id)}
                                />
                                {row.original.status.toUpperCase() ===
                                  "PENDENTE" && (
                                  <BiEdit
                                    style={{
                                      fontSize: "1.2rem",
                                      cursor: "pointer",
                                      color: "#C4C7CA",
                                      marginRight: "7px",
                                    }}
                                    onClick={() =>
                                      editRegister(row.original.id)
                                    }
                                  />
                                )}
                                <AiOutlineDownload
                                  style={{
                                    fontSize: "1.2rem",
                                    cursor: "pointer",
                                    color: "#C4C7CA",
                                    marginRight: "7px",
                                  }}
                                  onClick={() =>
                                    downloadRegister(row.original.id)
                                  }
                                />
                                {row.original.status.toUpperCase() ===
                                  "PENDENTE" && (
                                  <MdOutlineSettingsBackupRestore
                                    style={{
                                      fontSize: "1.2rem",
                                      cursor: "pointer",
                                      color: "#C4C7CA",
                                      marginRight: "7px",
                                    }}
                                    onClick={() =>
                                      estornarRegister(row.original.id)
                                    }
                                  />
                                )}
                                {row.original.status.toUpperCase() ===
                                  "PENDENTE" && (
                                  <TbCheckbox
                                    style={{
                                      fontSize: "1.2rem",
                                      cursor: "pointer",
                                      color: "#C4C7CA",
                                      marginRight: "7px",
                                    }}
                                    onClick={() =>
                                      aprovarRegister(row.original.id)
                                    }
                                  />
                                )}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <Row className="pt-4">
                  <Col>
                    <span style={{ color: "var(--color-cinza-medio)" }}>
                      Mostrando{" "}
                      {pagination.quantidade ? pagination.quantidade : "0"} de{" "}
                      {pagination.quantidadeTotal
                        ? pagination.quantidadeTotal
                        : "0"}
                    </span>
                  </Col>
                  <Col md={8}>
                    <Row className="float-right">
                      <ReactPaginate
                        previousLabel={"<"}
                        nextLabel={">"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        pageCount={pagination.totalPaginas}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={handlePageClick}
                        containerClassName={"pagination"}
                        subContainerClassName={"pages pagination"}
                        activeClassName={"active"}
                        forcePage={initialPage ? 0 : selectedPage}
                      />
                      <Input
                        type="select"
                        className="pageSize"
                        value={pageSize}
                        onChange={(e) => {
                          handleInitialPage(e.target.value);
                          setPageSize(Number(e.target.value));
                        }}
                      >
                        {[10, 25, 100].map((pageSize) => (
                          <option key={pageSize} value={pageSize}>
                            {pageSize}
                          </option>
                        ))}
                      </Input>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="align-items-center justify-content-end mt-5">
              <Col md={2}>
                <Button
                  className="purpleButton"
                  style={{
                    width: "100%",
                    height: "38px",
                  }}
                  onClick={() => {
                    const ids = contasPagarAgrupado
                      .filter((e) => e.isSelected)
                      .map((e) => e.id);
                    controller.aprovarContas(ids);
                  }}
                >
                  Aprovar itens selecionados
                </Button>
              </Col>
              <Col md={2}>
                <Button
                  style={{
                    width: "100%",
                    height: "38px",
                    backgroundColor: "var(--color-laranja)",
                    borderColor: "var(--color-laranja)",
                    color: "var(--color-branco)",
                  }}
                  onClick={() => {
                    const ids = contasPagarAgrupado
                      .filter((e) => e.isSelected)
                      .map((e) => e.id);
                    controller.estornarContas(ids);
                  }}
                >
                  Estornar itens selecionados
                </Button>
              </Col>
            </Row>
          </div>
          {
            <ModalDetalhamento
              isOpen={isModalDetalhamentoOpen}
              toggle={toggleDetalhamento}
              id={comissaoId}
            />
          }
          {
            <ModalEdicao
              isOpen={isModalEdicaoOpen}
              toggle={toggleEdicao}
              id={comissaoId}
              pagination={{
                search: search,
                pageSize: pageSize,
                selectedPage: selectedPage + 1,
              }}
            />
          }
        </Card>
      </Col>
      <div className="d-none">
        <a
          id="download"
          href={`data:application/pdf;base64,${download.arquivoBase64}`}
          download={download.nomeArquivo}
        >
          .
        </a>
      </div>
    </>
  );
};

export default Search;
